import { MutationTree } from 'vuex';
import { Project, StateProject } from './state';

const mutations: MutationTree<StateProject> = {
  setProjectAuth(state, projectAuth: string) {
    if (!projectAuth) {
      state.currentProjectAuth = null;
      state.currentProjectId = '';
    } else {
      console.log(projectAuth, 'id mut');
      state.currentProjectAuth = projectAuth;
      state.currentProjectId = projectAuth.split(':')[0];
    }
  },
  setProjects(state, projects) {
    state.projects = projects;
  },
  setProject(state, project) {
    const { id: projectId } = project;
    const id = projectId ? projectId : state.currentProjectId;
    const index = state.projects.findIndex((item) => item.id === id);
    state.projects[index] = { ...state.projects[index], ...project };
  },
  deleteProject(state, projectId: string) {
    const index = state.projects.findIndex((item) => item.id === projectId);
    if (index < 0) return;
    state.projects.splice(index, 1);
  },
  copyProject(state, { projectId, cloneProject }: { cloneProject: Project; projectId: string }) {
    const index = state.projects.findIndex((item) => item.id === projectId);
    state.projects.splice(index, 0, cloneProject);
  },
};

export default mutations;
