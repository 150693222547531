interface NLPVideoOptions {
  size: {
    width: number;
    height: number;
  };
  bitrate: number;
  fps: number;
}

interface NLPAudioOptions {
  bitrate: number;
  sampleRate: number;
}

interface NLPAppSettings {
  cameraCount: number;
  captureVideo: NLPVideoOptions;
  hqAudio: NLPAudioOptions;
  proxyVideo: NLPVideoOptions;
  proxyAudio: NLPAudioOptions;
}

const appSettings: NLPAppSettings = {
  cameraCount: 4,
  captureVideo: {
    size: {
      width: 1920,
      height: 1080,
    },
    fps: 30,
    bitrate: 10_000_000,
  },
  hqAudio: {
    bitrate: 96_000,
    sampleRate: 48000,
  },
  proxyVideo: {
    size: {
      width: 640,
      height: 360,
    },
    fps: 30,
    bitrate: 256_000,
  },
  proxyAudio: {
    bitrate: 64_000,
    sampleRate: 48000,
  },
};

export default appSettings;
