import TestRecorder from '@/views/tests/recorder.vue';
import TestDirector from '@/views/tests/director.vue';
import TestOperator from '@/views/tests/operator.vue';
import TestWebRTC from '@/views/tests/webrtc.vue';
import TestFrames from '@/views/tests/frames.vue';
import TestEditingList from '@/views/tests/editing.vue';
import TestCodecs from '@/views/tests/codecs.vue';
import LandingPage from '@/views/LandingPage.vue';

import TestMock from '@/views/tests/mock.vue';

export default [
  {
    path: '/test/codecs',
    name: 'test-codecs',
    component: TestCodecs,
  },
  {
    path: '/test/webrtc',
    name: 'test-webrtc',
    component: TestWebRTC,
  },
  {
    path: '/test/recorder',
    name: 'test-recorder',
    component: TestRecorder,
  },
  {
    path: '/test/frames',
    name: 'test-frames',
    component: TestFrames,
  },
  {
    path: '/test/editinglist',
    name: 'test-editinglist',
    component: TestEditingList,
  },
  // УДАЛИТЬ
  {
    path: '/test/director',
    name: 'test-director',
    component: TestDirector,
    meta: {
      auth: 'director',
    },
  },
  {
    path: '/test/operator/:camera',
    name: 'test-operator',
    component: TestOperator,
    meta: {
      auth: 'operator',
    },
  },
  {
    path: '/test/landing',
    name: 'test-landing',
    component: LandingPage,
  },
  // УДАЛИТЬ ПОСЛЕ СЛИЯНИЯ
  {
    path: '/test/login',
    name: 'test-login',
    component: TestMock,
    meta: {
      mock: 'SSO Login',
    },
  },
  {
    path: '/test/operator-login',
    name: 'test-operator-login',
    component: TestMock,
    meta: {
      mock: 'Operator Login',
    },
  },
];
