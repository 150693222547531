import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "range-input" }
const _hoisted_2 = ["min", "max", "step"]
const _hoisted_3 = { class: "range-input__line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "range-input__icon-plus",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.plusValue && _ctx.plusValue(...args)))
    }, [
      _createVNode(_component_Icon, { icon: "plus" })
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "range",
      min: _ctx.min,
      max: _ctx.max,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
      class: "range-input__slider",
      step: _ctx.step,
      id: "myRange"
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.value]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "range-input__circle",
        style: _normalizeStyle(_ctx.currentPosition)
      }, null, 4)
    ]),
    _createElementVNode("div", {
      class: "range-input__icon-minus",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.minusValue && _ctx.minusValue(...args)))
    }, [
      _createVNode(_component_Icon, { icon: "minus" })
    ])
  ]))
}