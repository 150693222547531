
import { defineComponent, ref } from 'vue';
import Icon from './common/icon/Icon.vue';

export default defineComponent({
  name: 'MenuEffects',
  setup() {
    const isOpenTransition = ref(false);
    const isOpenColors = ref(false);
    const openTransitions = (): void => {
      isOpenColors.value = false;
      isOpenTransition.value = !isOpenTransition.value;
    };
    const openColors = (): void => {
      isOpenColors.value = !isOpenColors.value;
      isOpenTransition.value = false;
    };
    return {
      isOpenTransition,
      isOpenColors,
      openTransitions,
      openColors,
    };
  },
  components: { Icon },
});
