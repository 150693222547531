
import { Ref, defineComponent, onBeforeUnmount, onBeforeUpdate, ref, onMounted, nextTick, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
import { NLPWebRTCConnector, NLPRtcConnectionSide } from '@/utils/webrtc';
import { NLPP2PEvents } from '@/utils/p2p-protocol-events';
import { NLPEditingList, NLPEditingEventType } from '@/utils/editinglist';
import { NLPMediaRecorder } from '@/utils/mediarecorder';
import { api } from '@/utils/api';
import { NLPDestroyList } from '@/utils/destroyer';
import { NLPVideoUpload } from '@/utils/videocapture';
import appEvents from '@/utils/events';

import { useLocalRecorder } from '@/composables/useWebrtc';
import { useCommon } from '@/composables/useCommon';
import { useVideoImage } from '@/composables/useVideoImage';

import ButtonUser from '@/components/common/buttons/Button.vue';
import MainVideo from '@/components/video/MainVideo.vue';
import PreviewVideo from '@/components/video/PreviewVideo.vue';
import Icon from '@/components/common/icon/Icon.vue';
import ModalSelectDevice from '@/components/modals/ModalSelectDevice.vue';
import ButtonRec from '@/components/common/buttons/ButtonRec.vue';
import CircleIconButton from '@/components/common/buttons/CircleIconButton.vue';
import Modal from '@/components/common/Modal.vue';
// import ProgressBar from '@/components/common/ProgressBar.vue';
import RangeInput from '@/components/common/inputs/RangeInput.vue';
import MenuEffects from '@/components/MenuEffects.vue';
import BurgerMenu from '@/components/common/BurgerMenu.vue';
import SettingsRecord from '@/components/common/SettingsRecord.vue';

export default defineComponent({
  name: 'ProducerView',
  components: {
    Icon,
    CircleIconButton,
    ButtonRec,
    ButtonUser,
    MainVideo,
    PreviewVideo,
    ModalSelectDevice,
    Modal,
    // ProgressBar,
    RangeInput,
    MenuEffects,
    BurgerMenu,
    SettingsRecord,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const { memory } = useCommon();

    const isShowSettings: Ref<boolean> = ref(false);
    const isShowDelete: Ref<boolean> = ref(false);

    const isShowModalSettings: Ref<boolean> = ref(false);
    const currentStep: Ref<string> = ref('');
    const selectedCam: Ref<number | undefined> = ref();
    const connectedCam: Ref<number | undefined> = ref();
    const pgm_video = ref();
    const local_time = ref<number>(0);
    const remote_resolution = ref<Array<string>>([]);
    const conns: Ref<Array<NLPWebRTCConnector | MediaStream | undefined>> = ref([]);
    const previevVideo: any = ref([]);
    const isShowModalDevice: Ref<boolean> = ref(false);
    const editingList = ref(new NLPEditingList(store.state.projects.currentProjectId));
    const isShowModalClose = ref<boolean>(false);
    const connectionState = ref<string>('');
    const flipVideo: Ref<Array<boolean>> = ref([]);
    const aiActive = ref<boolean>(false);

    const destroyers: NLPDestroyList = new NLPDestroyList();

    const {
      createRecorder,
      stopWriter: stopRecorder,
      startRecord: startLocationRecord,
      createWriter,
      destroyConn,
      setZoom,
      zoomParams,
    } = useLocalRecorder();

    const setPrevievVideo = (el: any) => {
      if (el) {
        previevVideo.value.push(el);
      }
    };

    const isConnected: Ref<{ [key: string]: boolean }> = ref({ 1: false, 2: false, 3: false, 4: false });

    const cameraCount = computed(() => {
      let cnt = 0;
      conns.value.forEach((conn) => {
        if (conn instanceof MediaStream) ++cnt;
        else if (conn instanceof NLPWebRTCConnector) {
          if (conn.valid && conn.connected) ++cnt;
        }
      });
      return cnt;
    });

    async function connectVideo(cam: number, conn: NLPWebRTCConnector | MediaStream) {
      const video_chn = previevVideo.value[cam - 1]?.$refs.input_video;
      console.log('connect video', cam, video_chn);
      if (conn instanceof NLPWebRTCConnector) {
        video_chn.srcObject = await conn.getReceiveStream();
        const video_pgm = pgm_video.value.$refs.video[cam - 1];
        video_pgm.srcObject = await conn.getReceiveStream();
        destroyers.deleteById(`webrtc-${cam}`);
        flipVideo.value[cam] = false;
      } else if (conn instanceof MediaStream) {
        video_chn.srcObject = conn;
        const video_pgm = pgm_video.value.$refs.video[cam - 1];
        video_pgm.srcObject = conn;
        destroyers.destroyById(`webrtc-${cam}`);
        const s = conn.getVideoTracks()[0]?.getSettings();
        flipVideo.value[cam] = s && s.facingMode === 'user';
      }

      video_chn.addEventListener(
        'loadedmetadata',
        () => {
          remote_resolution.value[cam] = `${video_chn.videoWidth}x${video_chn.videoHeight}`;
        },
        {
          once: true,
        }
      );
      conns.value[cam] = conn;
      isConnected.value[cam] = true;
      if (!selectedCam.value) {
        selectedCam.value = cam;
        isShowPgm.value = true;
      }
      currentStep.value = '';
    }

    const destroyAllConnection = () => {
      conns.value.forEach((conn, index) => {
        if (conn instanceof NLPWebRTCConnector) {
          conn?.p2p.emitRemote(NLPP2PEvents.delete());
          conn.destroy();
          isConnected.value[index] = false;
          conn = undefined;
        } else if (conn instanceof MediaStream) {
          destroyConn();
        }
      });
    };

    onBeforeUnmount(() => {
      if (!isUploadVideo.value) destroyAllConnection();

      const idCheckInterval = setInterval(() => {
        if (store.getters['upload/isUploadedAll']) {
          destroyAllConnection();
          clearInterval(idCheckInterval);
        }
      }, 100);
    });

    onBeforeUpdate(() => {
      previevVideo.value = [];
    });

    const typeButtonRec = ref<string>('start');

    const resultVideo = ref<Array<string>>([]);

    const stopRecord = async () => {
      stopAiTimer();
      typeButtonRec.value = 'start';

      conns.value.forEach((item) => {
        if (item instanceof NLPWebRTCConnector) {
          item?.p2p.emitRemote(NLPP2PEvents.stop());
        } else if (item instanceof MediaStream) {
          stopRecorder();
        }
      });

      editingList.value.stop();

      const vids = [];

      for (const dvr of dvrs.value) {
        const data = await dvr?.stop();
        vids.push(data);
      }

      vids.forEach((vid, idx) => {
        if (vid == null || !Array.isArray(resultVideo.value)) return;
        const url = window.URL.createObjectURL(vid);
        resultVideo.value[idx - 1] = url;
      });
      await sendInCloud();
      completeRecord();

      const rangeList = editingList.value.exportAsRanges(false);
      await api().uploadRangeList(rangeList);

      store.dispatch('video/getVideos', resultVideo.value);
      store.dispatch('video/getList', editingList.value);
      router.push('/video-editor');

      // currentStep.value = 'finished';
    };

    const dvrs: Ref<Array<NLPMediaRecorder | null>> = ref([]);

    let isStarting = false;
    const startRecord = async () => {
      if (isStarting === true) return;
      isStarting = true;
      try {
        typeButtonRec.value = 'stop';
        const dt0 = Date.now();
        api().sendDebug('startclick', { dt0: dt0 });
        for (const [index, item] of conns.value.entries()) {
          if (item instanceof NLPWebRTCConnector) {
            item?.p2p.emitRemote(NLPP2PEvents.start(index, dt0));
            item?.p2p.emitRemote(NLPP2PEvents.take(selectedCam.value || 0));
          } else if (item instanceof MediaStream) {
            startLocationRecord();
          }

          // record video
          if (!item) {
            dvrs.value[index] = null;
          } else {
            const dvr = new NLPMediaRecorder('cam' + index);

            if (item instanceof NLPWebRTCConnector) {
              dvr.start(await (item as NLPWebRTCConnector)?.getReceiveStream());
            } else if (item) {
              dvr.start(await item);
            }
            dvrs.value[index] = dvr;
          }
        }
        editingList.value.clear();
        editingList.value.start(selectedCam.value, dt0);
        api().sendDebug('start', { startDt: dt0 });
      } catch (err) {
        console.log('error when start', err);
      } finally {
        isStarting = false;
      }
    };

    const back = () => {
      currentStep.value = '';
    };

    const close = (isShowWarn: boolean) => {
      if (isShowWarn) {
        isShowModalClose.value = true;
        return;
      }
      if (!isUploadedAll.value) {
        store.dispatch('projects/deleteProject', store.state.projects.currentProjectId);
      }
      router.push('/producer-projects');
    };

    const selectCam = async (cam: number) => {
      currentStep.value = 'connection';
      connectedCam.value = cam;
      connectionState.value = '';
      const conn = new NLPWebRTCConnector(NLPRtcConnectionSide.Receiver, 'd', `o${cam}`);
      await conn.create();
      // перенесено в connectVideo
      //conns.value[cam] = conn;
      destroyers.destroyClass(conn, 'destroy', `webrtc-${cam}`);

      conn.on('pc:connectionstatechange', (state) => {
        connectionState.value = state;
      });

      conn.on('open', connectVideo.bind(this, cam, conn));
      conn.p2p.on(
        'event:uploadProgress',
        (camera: number, countFrames: number, totalFrames: number, done: boolean, error: boolean) => {
          store.dispatch('upload/updateRemoteUploadState', {
            camera: camera,
            active: !done,
            current: countFrames,
            total: totalFrames,
            error: error,
          });
        }
      );
    };

    const isShowPgm: Ref<boolean> = ref(false);

    const selectPgm = async (cam: number) => {
      if (selectedCam.value === cam) return;

      if (editingList.value.started) {
        editingList.value.appendEvent({
          dt: Date.now(),
          order: 0,
          type: NLPEditingEventType.TakeVideo,
          camera: cam,
        });
      }

      conns.value.forEach((conn) => {
        if (conn instanceof NLPWebRTCConnector) {
          conn.p2p.emitRemote(NLPP2PEvents.take(cam));
        }
      });

      selectedCam.value = cam;
      isShowPgm.value = true;
    };

    const addOwnCamera = async ({
      videoInput,
      audioInput,
      facingMode,
    }: {
      videoInput: string | null;
      audioInput: string;
      facingMode?: string;
    }) => {
      // сохраняем последние значения
      window.localStorage.setItem('app.ownCamera', videoInput || '');
      window.localStorage.setItem('app.ownMic', audioInput);
      window.localStorage.setItem('app.ownFacing', facingMode || '');
      isShowModalDevice.value = false;
      if (!connectedCam.value) return;
      console.log('connect cam', connectedCam.value);

      const cam = connectedCam.value;
      if (!cam) return;
      const stream = await createRecorder(cam, null, { videoInput, audioInput, facingMode });
      connectVideo(cam, stream);
      if (conns.value[cam] instanceof NLPWebRTCConnector) {
        destroyers.destroyById(`update-remote-time-${cam}`);
        (conns.value[cam] as NLPWebRTCConnector).destroy();
      }
      conns.value[cam] = stream;
      destroyers.destroyFunc(destroyConn, `local-cam-${cam}`);
      if (!selectedCam.value) {
        selectedCam.value = cam;
        isShowPgm.value = true;
      }
      isConnectedLocalCam.value = true;
      connectedCam.value = 0;
    };

    function stopAiTimer() {
      destroyers.destroyById('ai-switcher');
      aiActive.value = false;
    }

    const stopConnection = (cam: number) => {
      if (conns.value[cam] instanceof NLPWebRTCConnector) {
        (conns.value[cam] as NLPWebRTCConnector)?.p2p.emitRemote(NLPP2PEvents.delete());
        (conns.value[cam] as NLPWebRTCConnector)?.pc?.close();
        (conns.value[cam] as NLPWebRTCConnector).pc = null;
        isConnected.value[cam] = false;
        conns.value[cam] = undefined;
      } else if (conns.value[cam] instanceof MediaStream) {
        destroyConn();
        conns.value[cam] = undefined;
        isConnectedLocalCam.value = false;
        window.localStorage.removeItem('app.ownCamera');
        window.localStorage.removeItem('app.ownMic');
        window.localStorage.removeItem('app.ownFacing');
      }
      isConnected.value[cam] = false;
      isShowSettings.value = false;
      if (selectedCam.value === cam) selectedCam.value = undefined;
      stopAiTimer();
    };

    async function isCameraAllowed(): Promise<boolean> {
      // сделано так из-за того что PermissionName в ts не имеет вариантов camera, microphone
      //eslint-disable-next-line
      const query = (p: any) => navigator.permissions.query(p);
      const allowVideo = (await query({ name: 'camera' }))?.state === 'granted';
      const allowAudio = (await query({ name: 'microphone' }))?.state === 'granted';
      return allowVideo && allowAudio;
    }

    onMounted(async () => {
      destroyers.destroyInterval(
        window.setInterval(() => {
          local_time.value = Date.now();
        }, 1000),
        'update-local-time'
      );

      destroyers.destroyInterval(
        window.setInterval(async () => {
          api().reportDirectorIsOnline();
        }, 2000),
        'report-director'
      );
      if (await isCameraAllowed()) {
        console.log('camera allowed');
        const localCamera = window.localStorage.getItem('app.ownCamera');
        const localMic = window.localStorage.getItem('app.ownMic');
        const localFacing = window.localStorage.getItem('app.ownFacing');
        if ((localCamera || localFacing) && localMic) {
          console.log('add last used camera', localCamera, localFacing, localMic);
          connectedCam.value = 1;
          await addOwnCamera({
            videoInput: localCamera || null,
            audioInput: localMic,
            facingMode: localFacing || undefined,
          });
        } else {
          connectedCam.value = 1;
          showModalDevice();
        }
      }
    });

    const sendInCloud = async () => {
      const cx = editingList.value.exportForCloud();

      const ids = await api().uploadEditingList(cx);
      if (ids !== null) editingList.value.updateIdsFromCloud(ids);
      // router.push('/producer-projects');
    };

    const restartStream = async () => {
      stopAiTimer();
      currentStep.value = '';
      resultVideo.value = [];
      editingList.value.clear();
      await nextTick();

      conns.value.forEach((item, index) => {
        if (item instanceof NLPWebRTCConnector) {
          item?.p2p.emitRemote(NLPP2PEvents.restart(index));
        } else if (item instanceof MediaStream) {
          createWriter(index);
        }
        if (!item) return;
        connectVideo(index, item);
      });
    };

    onBeforeUnmount(async () => {
      destroyers.destroyAll();
      store.dispatch('upload/clearAll');
    });

    const isUploadVideo: Ref<boolean> = ref(false);

    const totalLength = ref<number[]>([]);

    const camsUpload = computed(() => {
      return store.state.upload;
    });

    const { createPreviewVideo } = useVideoImage();

    const isConnectedLocalCam = ref<boolean>(false);

    const completeRecord = async () => {
      isUploadVideo.value = true;

      destroyers.destroyById('report-director');
      const preview: string = await createPreviewVideo(
        resultVideo.value[(editingList.value?.list[1]?.camera || 1) - 1]
      );
      const start = editingList.value?.list[0].dt;
      const finished = editingList.value?.list[editingList.value?.list.length - 1].dt;
      store.dispatch('projects/updateProject', { image: preview, duration: finished - start });

      // заливка за 2 прохода: сначала передаем данные пирам
      for (const [index, item] of conns.value.entries()) {
        if (item) {
          const data = editingList.value.exportForPeer(index);

          if (item instanceof NLPWebRTCConnector) {
            item.p2p.emitRemote(NLPP2PEvents.uploadRanges(index, data, totalLength.value[index]));
          }
        }
      }

      // потом заливаем свое
      for (const [index, item] of conns.value.entries()) {
        if (item) {
          if (item instanceof MediaStream) {
            destroyConn();
            const U = new NLPVideoUpload(store.state.projects.currentProjectId);
            await U.open();

            try {
              await U.uploadAll(index);
            } catch (err) {
              window.alert(err);
            }
          }
        }
      }
    };

    // const uploadList = computed(() => {
    //   const list: string[] = [];
    //   conns.value.forEach((item, index) => {
    //     if (item instanceof NLPWebRTCConnector) {
    //       list[index] = 'remote';
    //     } else if (item instanceof MediaStream) {
    //       list[index] = 'local';
    //     }
    //   });
    //   return list;
    // });

    const isUploadedAll = computed(() => {
      return store.getters['upload/isUploadedAll'];
    });

    // watch(isUploadedAll, (newValue) => {
    //   if (newValue) {
    //     store.dispatch('video/getVideos', resultVideo.value);
    //     store.dispatch('video/getList', editingList.value);
    //     // надо показать, что все загружено
    //     window.setTimeout(() => {
    //       router.push('/video-editor');
    //     }, 1000);
    //   }
    // });

    const showEditorPage = ref<boolean>(true);

    const switchLocalCam = async () => {
      if (!isConnectedLocalCam.value) return;
      const cam = conns.value.findIndex((item) => item instanceof MediaStream);
      const st_video = (conns.value[cam] as MediaStream).getVideoTracks()[0];
      const st_audio = (conns.value[cam] as MediaStream).getAudioTracks()[0];
      if (!st_video) return;
      const facing = st_video.getSettings().facingMode;
      const reqFacing = facing === 'user' ? 'environment' : 'user';
      stopConnection(cam);
      connectedCam.value = cam;
      await addOwnCamera({
        videoInput: null,
        audioInput: st_audio?.getSettings().deviceId || '',
        facingMode: reqFacing,
      });
    };

    function aiSwitcherTimerCb(state: { count: number }) {
      if (currentStep.value !== '') return;
      --state.count;
      if (state.count > 0) return;
      state.count = (Math.random() * 4 + 3) | 0;
      for (;;) {
        const cam = (Math.random() * conns.value.length) | 0;
        if (cam === selectedCam.value) continue;
        if (conns.value[cam] === undefined) continue;
        selectPgm(cam);
        break;
      }
    }

    const toggleAi = (value: boolean) => {
      if (conns.value.length < 2) return;
      if (value === false) {
        aiActive.value = true;
        destroyers.destroyInterval(window.setInterval(aiSwitcherTimerCb, 1000, { count: 3 }), 'ai-switcher');
      } else {
        stopAiTimer();
      }
    };

    if (process?.env.NODE_ENV !== 'production') {
      const testRenderCb = async () => {
        console.log('start test render', store.state.projects.currentProjectId);
        const render_res = await api().renderVideo(
          store.state.projects.currentProjectId,
          (current: number, duration: number) => {
            console.log('rendering', current, duration);
          }
        );
        console.log('render res', render_res);
      };
      appEvents.on('test:call-render', testRenderCb);
      destroyers.destroyEvent('test:call-render', testRenderCb);
    }

    const showModalDevice = async () => {
      try {
        if (!(await isCameraAllowed())) {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
        isShowModalDevice.value = true;
      } catch (error) {
        store.dispatch('modals/getIsShowModalError', { value: true, text: 'нет доступных устройств' });
      }
    };

    return {
      isShowModalSettings,
      close,
      currentStep,
      selectedCam,
      connectedCam,
      selectCam,
      previevVideo,
      isConnected,
      setPrevievVideo,
      pgm_video,
      selectPgm,
      isShowPgm,
      isShowSettings,
      addOwnCamera,
      conns,
      isShowModalDevice,
      startRecord,
      stopConnection,
      stopRecord,
      typeButtonRec,
      resultVideo,
      dvrs,
      editingList,
      memory,
      restartStream,
      sendInCloud,
      completeRecord,
      // uploadList,
      isUploadVideo,
      store,
      totalLength,
      camsUpload,
      isUploadedAll,
      back,
      isShowModalClose,
      connectionState,
      cameraCount,
      flipVideo,
      switchLocalCam,
      showEditorPage,
      isConnectedLocalCam,
      setZoom,
      zoomParams,
      aiActive,
      toggleAi,
      showModalDevice,
      isShowDelete,
    };
  },
});
