import { EventEmitter } from 'eventemitter3';

import { api } from './api';
import { NLPPeerProtocol } from './p2p-protocol';

import { NLPTimeSync } from './timesync';

export enum NLPRtcConnectionSide {
  None,
  Receiver,
  Sender,
}

let baseConfig: RTCConfiguration | null = null;

interface PromiseResolver<Type> {
  resolve: (value: Type) => void;
  reject: (value: Error) => void;
}

export class NLPWebRTCConnector extends EventEmitter {
  // settings
  private updateTimeout = 250;

  // public
  pc: RTCPeerConnection | null = null;
  mode: NLPRtcConnectionSide = NLPRtcConnectionSide.None;
  id: string;
  reconnect = true;
  camNumber: number;
  p2p: NLPPeerProtocol;

  // private
  private localSignallingId: string;
  private remoteSignallingId: string;
  private updateTimer = -1;
  private remoteSdp: string | null = null;
  private mediaStream: MediaStream;
  private dataChannel: RTCDataChannel | null = null;
  private streamWaiters: Array<PromiseResolver<MediaStream>> = [];
  private timesync: NLPTimeSync | null = null;
  private senderIceStartDt = 0;
  private connectionPolingStart = 0;

  /**
   *
   * @param mode connection mode: sender/receiver
   * @param localId local identifier for signalling: d,oN
   * @param remoteId remote identifier for signalling: d,oN
   */
  constructor(mode: NLPRtcConnectionSide, localId: string, remoteId: string) {
    super();
    this.id = Math.random().toString(36).substring(3);
    this.mode = mode;
    this.camNumber = Number(remoteId.replace('o', ''));
    console.log(this.camNumber, mode, localId, remoteId, 'localIdlocalIdlocalIdlocalIdlocalIdlocalId');
    this.localSignallingId = localId;
    this.remoteSignallingId = remoteId;
    this.updateTimerCb = this.updateTimerCb.bind(this);
    this.mediaStream = new MediaStream();
    this.p2p = new NLPPeerProtocol(this);
  }

  /**
   * Create WebRTC connection
   * @param stream MediaStream for xmit
   */

  create(stream?: MediaStream): Promise<void> {
    if (this.mode === NLPRtcConnectionSide.Receiver) return this.createReceiver();
    else if (this.mode === NLPRtcConnectionSide.Sender && stream !== undefined) return this.createSender(stream);
    else throw new Error("Can't create NLPWebRTCConnector: bad arguments"); // eslint-disable-line
  }

  /**
   * Get received media sream
   * @returns Promise, that resolved to received MediaStream clone
   */
  getReceiveStream(): Promise<MediaStream> {
    if (this.valid === false) return Promise.reject(new Error('Connection is nod valid'));
    if (this.mode !== NLPRtcConnectionSide.Receiver)
      return Promise.reject(new Error('MediaStream available only in receiver'));
    const p = new Promise<MediaStream>((resolve, reject) => {
      if (this.pc?.connectionState === 'connected') return resolve(this.mediaStream.clone());
      this.streamWaiters.push({ resolve, reject });
    });
    return p;
  }

  private async loadBaseConfig() {
    if (baseConfig !== null) return baseConfig;
    const data = await api().get('/api/config/webrtc');
    baseConfig = {
      iceServers: data.iceServers,
    };
    return baseConfig;
  }

  private async createBaseConnection(): Promise<RTCPeerConnection> {
    if (this.valid === false) throw new Error('Connection is not valid');
    if (this.pc !== null) throw new Error('Peer connection already created');
    await api().webrtcCleanupSignalling(this.localSignallingId);
    const config = await this.loadBaseConfig();
    this.pc = new RTCPeerConnection({ ...config });
    this.pc.addEventListener('icecandidate', this.ev_onicecandiate.bind(this));
    this.pc.addEventListener('negotiationneeded', this.ev_onnegotiationneeded.bind(this), { once: true });
    this.pc.addEventListener('connectionstatechange', this.ev_connectionstatechange.bind(this));
    this.ev_ontrack = this.ev_ontrack.bind(this);
    this.pc.addEventListener('track', this.ev_ontrack);
    this.pc.addEventListener('datachannel', this.ev_ondatachannel.bind(this));
    this.dataChannel = null;
    return this.pc;
  }

  private async createReceiver() {
    const pc = await this.createBaseConnection();
    if (pc !== null) pc.dispatchEvent(new Event('negotiationneeded'));
  }

  public async changeTrack(stream: MediaStream) {
    if (this.pc === null) return;

    const [videoTrack] = stream.getVideoTracks();
    const sender = this.pc.getSenders().find((s) => s.track?.kind === videoTrack.kind);
    if (!sender) return;
    sender.replaceTrack(videoTrack);
  }

  private async createSender(stream: MediaStream) {
    await api().webrtcCleanupSignalling(this.remoteSignallingId);
    const pc = await this.createBaseConnection();
    this.createDataChannel(pc);
    /*
    const availCodecs = RTCRtpSender.getCapabilities('video')?.codecs || [];
    console.log('Available codecs', availCodecs);

    const forceCodecs = availCodecs.filter((codec) => {
      return codec.mimeType.toLowerCase().includes('h264');
    });
    */
    stream.getTracks().forEach(async (track) => {
      //console.log('sending track', track, track.getSettings());
      const trackSettings = track.getSettings();
      const sender = pc.addTrack(track);
      if (track.kind !== 'video') return; // skip for non video tracks

      const params = sender.getParameters();
      console.log(params);
      /*
      const transceiver = pc.getTransceivers().find((t) => t.sender && t.sender.track === track);
      console.log(transceiver);
      if (forceCodecs.length > 0) {
        try {
          transceiver?.setCodecPreferences(forceCodecs);
        } catch (err) {
          console.log('Can not force to use H264');
        }
      }
      */
      if (params.encodings[0]) {
        params.encodings[0].maxFramerate = 30;
        params.encodings[0].maxBitrate = 256000;
        if (trackSettings.height) {
          params.encodings[0].scaleResolutionDownBy = Math.max(trackSettings.height / 360);
        } else {
          params.encodings[0].scaleResolutionDownBy = 4;
        }
      }
      await sender.setParameters(params);
    });
  }

  private createDataChannel(pc: RTCPeerConnection) {
    if (this.dataChannel !== null) {
      this.dataChannel.close();
      this.dataChannel = null;
    }
    this.dataChannel = pc.createDataChannel('ctl', {
      ordered: true,
      //maxPacketLifeTime:30000,
      maxRetransmits: 5,
    });
    this.dataChannel.binaryType = 'arraybuffer';
    this.dataChannel.addEventListener('open', this.ev_datachannelopen.bind(this));
    this.dataChannel.addEventListener('close', this.ev_datachannelclose.bind(this));
    this.dataChannel.addEventListener('message', this.ev_datachannelmessage.bind(this));
  }

  private async ev_ontrack(ev: RTCTrackEvent) {
    console.log('track', this.id, ev.track);
    this.mediaStream.addTrack(ev.track);
    this.emit('pc:track', ev);
  }

  private async ev_ondatachannel(ev: RTCDataChannelEvent) {
    console.log('datachannel', this.id, ev);
    this.dataChannel = ev.channel;
    this.dataChannel.addEventListener('open', this.ev_datachannelopen.bind(this));
    this.dataChannel.addEventListener('close', this.ev_datachannelclose.bind(this));
    this.dataChannel.addEventListener('error', this.ev_datachannelerror.bind(this));
    this.dataChannel.addEventListener('message', this.ev_datachannelmessage.bind(this));
  }

  private async ev_onicecandiate(ev: RTCPeerConnectionIceEvent) {
    console.log('candidate', this.id, ev.candidate?.sdpMLineIndex, ev.candidate?.sdpMid, ev.candidate?.candidate);
    if (ev.candidate !== null) await api().webrtcUpdatePeer(this.localSignallingId, ev.candidate);
    else if (this.pc?.iceGatheringState === 'complete') await api().webrtcUpdatePeer(this.localSignallingId, null);
  }

  private async updateTimerCb() {
    this.updateTimer = -1;
    if (this.pc === null) return;
    if (this.pc?.connectionState === 'connected') return;

    if (this.remoteSdp === null) {
      this.remoteSdp = await api().webrtcGetSdp(this.remoteSignallingId);
      if (this.remoteSdp !== null) {
        console.log('remote sdp', this.id);
        await this.pc?.setRemoteDescription({
          type: this.mode === NLPRtcConnectionSide.Sender ? 'answer' : 'offer',
          sdp: this.remoteSdp,
        });
        if (this.mode === NLPRtcConnectionSide.Receiver) {
          const answer = await this.pc?.createAnswer();
          if (answer !== null) {
            await this.pc?.setLocalDescription(answer);
            await api().webrtcUpdateSdp(this.localSignallingId, this.pc.localDescription);
          }
        }
      }
    }

    if (this.remoteSdp !== null) {
      const peers = await api().webrtcGetPeers(this.remoteSignallingId);

      peers.sort((a, b) => {
        if (a === null) return 1;
        if (b === null) return -1;
        if (a.ml === b.ml) {
          if (a.data < b.data) return -1;
          if (a.data > b.data) return 1;
          return 0;
        }
        return a.ml - b.ml;
      });

      console.log('peers', this.id, peers);

      for (let i = 0; i < peers.length; ++i) {
        const peer = peers[i];
        if (peer === null) continue;
        await this.pc.addIceCandidate({
          sdpMid: peer.mid,
          sdpMLineIndex: peer.ml,
          candidate: peer.data,
        });
      }

      if (peers.includes(null)) {
        console.log('last candidate', this.id);
        this.pc.addIceCandidate({ candidate: '' });
        return; // все, больше пиров не будет
      }
    } else if (this.mode === NLPRtcConnectionSide.Sender && this.pc !== null) {
      const now = Date.now();

      if (now - this.senderIceStartDt > 5000) {
        if (this.pc.connectionState === 'connecting') {
          console.log('reconnect sender by timeout');
          this.remoteSdp = null;
          this.mediaStream = new MediaStream();
          this.pc.addEventListener('negotiationneeded', this.ev_onnegotiationneeded.bind(this), { once: true });
          if (this.mode === NLPRtcConnectionSide.Sender) {
            this.pc.restartIce();
            this.createDataChannel(this.pc);
          }
        }
      }
    }

    if (Date.now() - this.connectionPolingStart > 300000) {
      console.log('Too long SDP poling - stop');
      return;
    }
    this.updateTimer = window.setTimeout(this.updateTimerCb, this.updateTimeout);
  }

  private async ev_onnegotiationneeded(ev: Event) {
    console.log('ev_onnegotiationneeded', this.id);
    if (ev.target !== this.pc) return;
    if (this.pc === null) return;
    try {
      if (this.mode === NLPRtcConnectionSide.Sender) {
        const offer = await this.pc.createOffer({
          offerToReceiveAudio: false,
          offerToReceiveVideo: false,
          iceRestart: true,
        });
        await this.pc?.setLocalDescription(offer);
        await api().webrtcUpdateSdp(this.localSignallingId, this.pc.localDescription);
        this.senderIceStartDt = Date.now();
      }
      if (this.updateTimer !== -1) window.clearTimeout(this.updateTimer);
      this.updateTimer = window.setTimeout(this.updateTimerCb, this.updateTimeout);
      this.connectionPolingStart = Date.now();
    } catch (err) {
      console.log('Error in negotiation', err);
    }
  }

  private async ev_connectionstatechange(ev: Event) {
    if (ev.target !== this.pc || this.pc === null) return;
    const state = this.pc?.connectionState;
    this.emit('pc:connectionstatechange', state, ev);
    console.log('connectionstatechange', this.id, state);
    if (state === 'connected') {
      this.streamWaiters.forEach((P: PromiseResolver<MediaStream>) => {
        P.resolve(this.mediaStream.clone());
      });
      this.emit('updatemediastream', this);
      this.openConnection();
      window.setTimeout(() => {
        api().webrtcCleanupSignalling(this.localSignallingId);
      }, 1000);
    } else if (state === 'disconnected' || state === 'failed') {
      if (this.reconnect === false) return;
      console.log('reconnect webrtc');
      this.remoteSdp = null;
      this.pc.addEventListener('negotiationneeded', this.ev_onnegotiationneeded.bind(this), { once: true });
      if (this.mode === NLPRtcConnectionSide.Sender) {
        this.pc.restartIce();
        this.createDataChannel(this.pc);
      } else if (this.mode === NLPRtcConnectionSide.Receiver) {
        this.dataChannel = null;
        this.pc.removeEventListener('track', this.ev_ontrack);
        this.pc.addEventListener('track', this.ev_ontrack);
        this.pc.dispatchEvent(new Event('negotiationneeded'));
      }
      this.mediaStream = new MediaStream();
    }
  }

  private async ev_datachannelopen(ev: Event) {
    console.log('data open', this.id, ev);
    api().sendDebug('pcdc', `Data channel open ${this.id}`);
  }

  private async ev_datachannelerror(ev: Event) {
    console.log('data error', this.id, ev);
    api().sendDebug('pcdc', `Data channel error ${this.id}`);
  }

  private async ev_datachannelclose(ev: Event) {
    console.log('data close', this.id, ev);
    api().sendDebug('pcdc', `Data channel close ${this.id}`);
    this.dataChannel = null;
    this.closeConnection();
  }

  private async ev_datachannelmessage(ev: MessageEvent) {
    //console.log('data message',this.id,ev);
    this.p2p.incomeMessage(ev);
  }

  timesyncMessage(msg: string) {
    if (this.timesync) {
      if (this.timesync.procedRemotePacket(msg) === true) {
        this.emit('timesync', this.timesync.remote_time, this.timesync.rtt);
      }
    }
  }

  private timesyncTx(data: string): boolean {
    if (this.dataChannel === null) return false;
    this.dataChannel.send(`["timesync",${JSON.stringify(data)}]`);
    return true;
  }

  //eslint-disable-next-line
  sendData(data: any) {
    if (this.valid === false) return false;

    /**
     * todo: добавить сохранение сообщений если канал с данными не открыт
     * периодически их чистить, если устарели
     */
    if (data === '') return true;
    if (this.dataChannel === null) return false;

    if (this.dataChannel.readyState !== 'open') {
      console.log('Data connection is not open');
      return false;
    }

    this.dataChannel.send(data);
    return true;
  }

  private openConnection() {
    this.timesync = new NLPTimeSync(this.timesyncTx.bind(this), 1000);
    console.log('open', this.remoteSignallingId);
    if (this.pc) {
      /*
      const rcvs = this.pc.getReceivers();
      rcvs.forEach((R) => {
        console.log('receiver', R, R.getParameters());
      });
      */
      const trns = this.pc.getTransceivers();
      const addTracks = this.mediaStream.active === false;
      trns.forEach((T) => {
        //console.log(T);
        if (this.mode === NLPRtcConnectionSide.Receiver && addTracks) {
          this.mediaStream.addTrack(T.receiver.track);
        }
      });
    }
    this.emit('open');
  }

  private closeConnection() {
    this.emit('close');
    this.timesync?.reset(false);
    this.timesync = null;
  }

  get valid() {
    return this.id !== '';
  }

  destroy() {
    if (this.valid === false) throw new Error('Connection is not valid');

    if (this.pc !== null) {
      window.setTimeout(
        (pc: RTCPeerConnection) => {
          pc.close();
        },
        1000,
        this.pc
      );
      this.pc = null;
    }
    this.id = '';
  }

  get remoteTime(): number {
    if (this.valid === false) throw new Error('Connection is not valid');

    if (this.timesync === null) return 0;
    return this.timesync.remote_time;
  }

  get localTimeDiff(): number {
    if (this.valid === false) throw new Error('Connection is not valid');

    if (this.timesync === null) return 0;
    return this.timesync.local_time_diff;
  }

  get rtt(): number {
    if (this.valid === false) throw new Error('Connection is not valid');

    if (this.timesync === null) return 0;
    return this.timesync.rtt;
  }

  get connected(): boolean {
    if (this.valid === false) throw new Error('Connection is not valid');

    return this.pc?.connectionState === 'connected';
  }
}
