
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useWebrtc } from '@/composables/useWebrtc';
import { api } from '@/utils/api';

export default defineComponent({
  name: 'TestWebRTC',
  components: {},
  setup() {
    const route = useRoute();
    const camera = parseInt('' + route?.params?.camera);

    const { createConnection, stream_hq, writer, local_video, local_time, remote_time, border_color } = useWebrtc();

    onMounted(async () => {
      if ((await api().projectDirectorOnline()) === -1) alert('director not online');
      else createConnection(camera, null, { videoInput: 'screen', audioInput: 'screen' });
    });

    function cmd_stop() {
      if (!stream_hq) return;
      stream_hq.value?.getTracks().forEach((track) => track.stop());
      if (writer) writer.value?.stop();
    }

    return {
      cmd_stop,
      local_video,
      local_time,
      remote_time,
    };
  },
});
