
import { defineComponent, onMounted } from 'vue';

import { NLPEditingList, NLPEditingEventType, flattenRangesList } from '@/utils/editinglist';
import { api } from '@/utils/api';

function sleep(ms: number) {
  return new Promise((resolve) => {
    window.setTimeout(resolve, ms);
  });
}

export default defineComponent({
  name: 'TestEditingList',
  components: {},
  setup() {
    onMounted(async () => {
      console.log('test editing list');
      const L = new NLPEditingList('test');

      let dt = 1685617424573;
      L.start(1, dt);

      let camera = 1;

      while (dt < 1685617483176) {
        for (;;) {
          let newCamera = (Math.random() * 4 + 1) | 0;
          if (newCamera !== camera) {
            camera = newCamera;
            break;
          }
        }
        dt += 5000;
        L.appendEvent({
          dt: dt,
          order: 0,
          type: NLPEditingEventType.TakeVideo,
          camera: camera,
        });
      }

      L.stop(dt);
      // eslint-disable-next-line
      if (0) {
        const cx = L.exportForCloud();
        console.log(cx);

        const ids = await api().uploadEditingList(cx);
        console.log(ids);
        if (ids !== null) L.updateIdsFromCloud(ids);
      }

      L.dump();
      L.generateCloudIds();
      L.convertToRanges();
      const RL = L.exportAsRanges(false);
      console.log('rl', RL);

      api().setProjectAuth('944996c2-d981-4a46-879b-3bcc0545cff6:75HfoNAxbXUrwkvdl4Jdcw==', 1);

      const u = await api().uploadRangeList(RL);
      console.log('upoload', u);

      //console.log(flattenRangesList(RL, NLPEditingEventType.TakeVideo));

      //console.log(L.exportForPeer(1));
    });

    return {};
  },
});
