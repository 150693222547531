import { NLPUploadState } from './state';

const getters = {
  isUploadedAll(state: NLPUploadState) {
    const isLocalLoading = state.local.findIndex((item) => item?.active && item?.current > 0);
    const isRemoteLoading = state.remote.findIndex((item) => item?.active && item?.current > 0);

    const isStartLoading =
      state.local.findIndex((item) => item?.current > 0) < 0 && state.remote.findIndex((item) => item?.current > 0) < 0;

    return isLocalLoading < 0 && isRemoteLoading < 0 && !isStartLoading;
  },
};

export default getters;
