
import { defineComponent, computed, ref, onMounted, onBeforeUnmount } from 'vue';
import Icon from '@/components/common/icon/Icon.vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';

export default defineComponent({
  name: 'Landing-page',
  components: { Icon },
  setup() {
    const router = useRouter();
    const store = useStore();
    const windowWidth = ref(0);

    const textArray = [
      { text: 'Снимайте видео с нескольких смартфонов одновременно', L: '70px', M: '41px', S: '29px' },
      {
        text: 'Откройте новые горизонты для творчества, снимая видео с нескольких смартфонов одновременно',
        L: '70px',
        M: '36px',
        S: '29px',
      },
      { text: 'Одно событие с разных ракурсов', L: '70px', M: '36px', S: '29px' },
      { text: 'Ловите нюансы с разных ракурсов', L: '70px', M: '36px', S: '29px' },
      {
        text: 'Добавьте динамики в видео благодаря съёмке с нескольких ракурсов одновременно',
        L: '70px',
        M: '36px',
        S: '29px',
      },
      { text: 'Мультисмартфоновая съёмка с разных ракурсов', L: '70px', M: '36px', S: '29px' },
      { text: 'Съёмка и монтаж одновременно с нескольких смартфонов', L: '70px', M: '36px', S: '29px' },
      { text: 'Монтаж ролика сразу во время съёмки на несколько смартфонов', L: '70px', M: '36px', S: '29px' },
    ];

    const videoArray = ['vid1.mp4', 'vid2.mp4'];

    const setVideo = (video: string) => {
      localStorage.setItem('landVideo', video);
    };

    const setText = (text: string) => {
      localStorage.setItem('landText', text);
    };

    const getVideo = () => {
      const videoNameStorage = localStorage.getItem('landVideo');
      let vidArr: string[] = [];
      if (videoNameStorage) {
        vidArr = videoArray.filter((video) => video != videoNameStorage);
      } else {
        vidArr = videoArray;
      }
      const item = vidArr[Math.floor(Math.random() * vidArr.length)];

      setVideo(item);
      return `/videos/${item}`;
    };

    const getText = () => {
      const textStorage = localStorage.getItem('landText');
      let textArr: Array<{ text: string; L: string; M: string; S: string }> = [];
      if (textStorage) {
        textArr = textArray.filter((text) => text.text != textStorage);
      } else {
        textArr = textArray;
      }
      var item = textArr[Math.floor(Math.random() * textArr.length)];
      setText(item.text);
      return item;
    };

    const text = ref(getText());
    const video = ref(getVideo());

    const fontSize = computed(() => {
      if (windowWidth.value < 768) {
        return text.value.S;
      } else if (windowWidth.value > 767 && windowWidth.value < 1920) {
        return text.value.M;
      }
      return text.value.L;
    });

    const socialAuth = (social: string) => {
      switch (social) {
        case 'yandex':
          window.location.replace('/api/login/yandex');
          break;
        case 'vk':
          window.location.replace('/api/login/vk');
          break;
        case 'google':
          window.location.replace('/api/login/google');
          break;
      }
    };

    const updateWindowWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      if (store.state.auth.loggedUser) {
        router.push('/roles');
      }
      updateWindowWidth();
      window.addEventListener('resize', updateWindowWidth);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateWindowWidth);
    });

    return {
      text,
      video,
      fontSize,
      socialAuth,
    };
  },
});
