
import { computed, defineComponent, onMounted, ref } from 'vue';
import ModalError from '@/components/modals/ModalError.vue';
import { useSize } from '@/composables/useSize';
// import { useSupportBrowser } from '@/composables/useSupportBrowser';
import Modal from './components/common/Modal.vue';
import ButtonUser from './components/common/buttons/Button.vue';
import Preloader from './components/common/Preloader.vue';
import { useStore } from '@/store';
// import pwa from './components/pwa.vue';

export default defineComponent({
  components: { ModalError, Modal, ButtonUser, Preloader },
  setup(props) {
    const { setVarWindowHeight } = useSize();
    const store = useStore();
    // const { checkSupportBrowser, checkWebView, listErrorSupport, checkIsSupportVersionChrome } = useSupportBrowser();
    const isShowMadalSupportError = ref(false);
    const isShowBtnShare = ref(false);
    const isSupportVersion = ref(false);

    const registration: any = ref(null);
    const updateExists = ref(false);
    const refreshing = ref(false);

    const updateAvailable = (event: any) => {
      registration.value = event.detail;
      updateExists.value = true;
    };

    const refreshApp = () => {
      updateExists.value = false;
      if (!registration.value || !registration.value.waiting) return;
      // registration.value.waiting.postMessage({ type: 'SKIP_WAITING' });
      registration.value.waiting.skipWaiting();
    };

    // const checkBrowser = () => {
    //   if (process?.env.NODE_ENV === 'production') {
    //     isSupportVersion.value = checkIsSupportVersionChrome();
    //     if (checkWebView() || checkSupportBrowser()) isShowMadalSupportError.value = true;
    //   }
    // };

    // expose({ checkBrowser });

    const isShowPreloader = computed(() => {
      console.log(store);
      return store.state.modals.isShowPreloader;
    });

    onMounted(() => {
      setVarWindowHeight();
      if ('canShare' in navigator) isShowBtnShare.value = true;
      document.addEventListener('swUpdated', updateAvailable, { once: true });
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing.value) return;
        refreshing.value = true;
        window.location.reload();
      });
    });

    const shareGoogle = async () => {
      try {
        await navigator.share({ url: `${location.origin}` });
      } catch (err) {
        console.log(err);
      }
    };

    return {
      shareGoogle,
      isShowMadalSupportError,
      isShowBtnShare,
      // listErrorSupport,
      isSupportVersion,
      updateExists,
      isShowPreloader,
      refreshApp,
    };
  },
});
