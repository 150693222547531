import { NLPEditingList } from '@/utils/editinglist';

export interface StateProject {
  videos: string[];
  list: NLPEditingList | null;
  currentTime: number;
  duration: number;
}

const state: StateProject = {
  videos: [
    // 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4',
    // 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
  ],
  list: null,
  currentTime: 0,
  duration: 0,
};

export type State = typeof state;
export default state;
