
import { defineComponent, onBeforeUpdate, ref, toRefs, watch } from 'vue';
import GenerateQr from '@/components/common/qr/GenerateQr.vue';
import ReaderQr from '@/components/common/qr/ReaderQr.vue';
import Icon from '@/components/common/icon/Icon.vue';
import { NLPEditingEventType, NLPEditingEvent } from '@/utils/editinglist';

export default defineComponent({
  name: 'MainVideo',
  components: {
    GenerateQr,
    ReaderQr,
    Icon,
  },
  props: {
    type: {
      default: '',
      type: String,
    },
    cam: {
      type: Number,
      default: null,
    },
    connectedCam: {
      type: Number,
      default: null,
    },
    isShowPgm: {
      type: Boolean,
      default: false,
    },
    previews: {
      type: Array<string>,
      default: [],
    },
    editingList: {
      type: Object,
      default: null,
    },
    connectionState: {
      type: String,
      default: '',
    },
    flipVideo: {
      type: Array<boolean>,
      default: [],
    },
  },
  setup(props) {
    const { editingList, type, connectionState } = toRefs(props);

    const playingVideo = ref<boolean>(false);
    const replayVideo = ref<Array<HTMLVideoElement>>([]);
    const currentPlayingCamera = ref<number>(0);
    let replayUpdateTimeout: ReturnType<typeof setTimeout> | null = null;
    const takeIdx = ref<number>(0);
    const seekPos = ref<number>(0);

    const setReplayVideo = (el: any) => {
      if (el) {
        replayVideo.value.push(el);
      }
    };

    const stopVideoReplay = () => {
      if (!playingVideo.value) return;
      playingVideo.value = false;

      if (replayUpdateTimeout !== null) {
        window.clearTimeout(replayUpdateTimeout);
        replayUpdateTimeout = null;
      }

      replayVideo.value.forEach((element: HTMLVideoElement) => {
        element?.pause();
      });
    };

    const replayUpdate = (newIndex: number) => {
      replayUpdateTimeout = null;
      const elCur: NLPEditingEvent | undefined = editingList.value.list[newIndex];
      const elNext: NLPEditingEvent | undefined = editingList.value.list[newIndex + 1];
      if (elNext === undefined || elCur === undefined) {
        stopVideoReplay();
        return;
      }
      if (elCur.type === NLPEditingEventType.TakeVideo) {
        currentPlayingCamera.value = elCur.camera || 0;
        takeIdx.value = newIndex;
      }
      replayUpdateTimeout = setTimeout(replayUpdate, elNext.dt - elCur.dt, newIndex + 1);
    };

    const startVideoReplay = () => {
      if (replayVideo.value.length === 0) return;

      seekPos.value = replayVideo.value[0].currentTime;
      // если уже конец файла - перемотка назад
      if (seekPos.value >= replayVideo.value[0].duration) {
        seekPos.value = 0;
        takeIdx.value = 0;
      }
      const list = editingList.value.list;
      let camera = 0;
      // let takeIdx = 0;

      // ищем какую камеру переключить
      if (currentPlayingCamera.value === 0) {
        list.find((el: NLPEditingEvent, idx: number) => {
          if (el.type === NLPEditingEventType.Start) return false;
          if (el.dt - list[0].dt > seekPos.value) return true;
          if (el.type === NLPEditingEventType.TakeVideo) {
            camera = el.camera || 0;
            takeIdx.value = idx;
          }
          return list[idx + 1] === undefined || list[idx + 1].type === NLPEditingEventType.Stop;
        });
        currentPlayingCamera.value = camera;
      }

      // проматываем монтажный лист
      replayUpdate(takeIdx.value);

      // перематываем все видосики на нужное время
      replayVideo.value.forEach((element: HTMLVideoElement) => {
        element.currentTime = seekPos.value;
        element.play();
      });

      playingVideo.value = true;
    };

    watch(type, (newType) => {
      if (newType === 'finished') {
        const firstTake: NLPEditingEvent = editingList.value.list.find((el: NLPEditingEvent) => {
          return el.type === NLPEditingEventType.TakeVideo;
        });
        if (!firstTake) return;

        // тут все мотаем в начало
        replayVideo.value.forEach((element: HTMLVideoElement) => {
          element.currentTime = 0;
        });

        // показываем видосик с первой тейкнутой камерой
        currentPlayingCamera.value = firstTake.camera || 0;
      } else {
        currentPlayingCamera.value = 0;
      }
    });

    onBeforeUpdate(() => {
      replayVideo.value = [];
    });

    return {
      startVideoReplay,
      stopVideoReplay,
      replayVideo,
      playingVideo,
      currentPlayingCamera,
      setReplayVideo,
    };
  },
});
