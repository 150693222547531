import appSettings from '@/app-settings';

export interface NLPUploadProgress {
  camera: number;
  active: boolean;
  current: number;
  total: number;
  error: boolean;
  updated: number; // время обновления
}

export interface NLPUploadState {
  local: Array<NLPUploadProgress>;
  remote: Array<NLPUploadProgress>;
  render: number;
}

const state: NLPUploadState = {
  local: [],
  remote: [],
  render: 0,
};

for (let i = 1; i <= appSettings.cameraCount; ++i) {
  state.local[i] = {
    camera: i,
    active: false,
    current: 0,
    total: 0,
    error: false,
    updated: 0,
  };
  state.remote[i] = {
    camera: i,
    active: false,
    current: 0,
    total: 0,
    error: false,
    updated: 0,
  };
}

export type State = typeof state;
export default state;
