export interface Modals {
  isShowModalError: boolean;
  isShowModalSuccess: boolean;
  errorText: string;
  isShowPreloader: boolean;
}

const state: Modals = {
  isShowModalError: false,
  isShowModalSuccess: false,
  errorText: '',
  isShowPreloader: false,
};

export type State = typeof state;
export default state;
