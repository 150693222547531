import { Commit } from 'vuex';
import { NLPEditingEvent } from '@/utils/editinglist';

export default {
  async getVideos({ commit }: { commit: Commit }, data: string[]) {
    commit('setVideos', data);
  },

  async getList({ commit }: { commit: Commit }, data: NLPEditingEvent) {
    commit('setList', data);
  },
  async getCurrentTime({ commit }: { commit: Commit }, time: number) {
    commit('setCurrentTime', time);
  },
  async getDuration({ commit }: { commit: Commit }, time: number) {
    commit('setDuration', time);
  },
};
