import { MutationTree } from 'vuex';

import appSettings from '@/app-settings';

import { NLPUploadProgress, NLPUploadState } from './state';

const mutations: MutationTree<NLPUploadState> = {
  setLocalUploadState(state, S: NLPUploadProgress) {
    const camera = S.camera;
    if (camera < 1 || camera > appSettings.cameraCount) throw new Error(`Bad camera number: ${camera}`);
    const { active, current, total, error } = S;
    state.local[camera].active = active;
    state.local[camera].current = current;
    state.local[camera].total = total;
    state.local[camera].error = error;
    state.local[camera].updated = Date.now();
  },
  setRemoteUploadState(state, S: NLPUploadProgress) {
    const camera = S.camera;
    if (camera < 1 || camera > appSettings.cameraCount) throw new Error(`Bad camera number: ${camera}`);
    const { active, current, total, error } = S;
    state.remote[camera].active = active;
    state.remote[camera].current = current;
    state.remote[camera].total = total;
    state.remote[camera].error = error;
    state.remote[camera].updated = Date.now();
  },
  setLocalUploadProgress(state, data: { camera: number; value: number; active: boolean; error: boolean }) {
    const camera = data.camera;
    if (camera < 1 || camera > appSettings.cameraCount) throw new Error(`Bad camera number: ${camera}`);
    state.local[camera].current = data.value;
    state.local[camera].active = data.active;
    state.local[camera].error = data.error;
    state.local[camera].updated = Date.now();
  },
  setRemoteUploadProgress(state, data: { camera: number; value: number; active: boolean; error: boolean }) {
    const camera = data.camera;
    if (camera < 1 || camera > appSettings.cameraCount) throw new Error(`Bad camera number: ${camera}`);
    state.remote[camera].current = data.value;
    state.remote[camera].active = data.active;
    state.remote[camera].error = data.error;
    state.remote[camera].updated = Date.now();
  },
  clearAll(state) {
    for (let i = 1; i <= appSettings.cameraCount; ++i) {
      state.local[i] = {
        camera: i,
        active: false,
        current: 0,
        total: 0,
        error: false,
        updated: 0,
      };
      state.remote[i] = {
        camera: i,
        active: false,
        current: 0,
        total: 0,
        error: false,
        updated: 0,
      };
    }
    state.render = 0;
  },
  setRenderProgress(state, value) {
    state.render = value;
  },
};

export default mutations;
