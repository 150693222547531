import { createStore, useStore as baseUseStore, Store as VuexStore } from 'vuex';
import { InjectionKey } from 'vue';

import auth, { State as AuthState } from './auth/index';

import projects, { State as ProjectsState } from './projects/index';

import modals, { State as ModalsState } from './modals/index';

import upload, { State as UploadState } from './upload/index';

import video, { State as VideoState } from './video/index';
export interface State {
  isPwa: boolean;
}

const root = {
  state: {
    isPwa: window.matchMedia('(display-mode: standalone)').matches,
  },
};
export interface RootState extends State {
  auth: AuthState;
  projects: ProjectsState;
  modals: ModalsState;
  upload: UploadState;
  video: VideoState;
  /* other: OtherState; */
}

export type RootStore = any;
//   ProjectsStore<Pick<RootState, 'projects'>> &
//   ModalsStore<Pick<RootState, 'modals'>>

export const key: InjectionKey<VuexStore<RootState>> = Symbol();
export default createStore<RootState>({
  modules: {
    auth,
    projects,
    modals,
    upload,
    video,
    root,
    // other
  },
});

export function useStore() {
  return baseUseStore(key);
}
