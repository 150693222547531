
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'CheckboxVue',
  props: {
    modelValue: [Boolean, String, Number, Array as () => Array<string | number>],
    value: [String, Number, Array as () => Array<string>],
    trueValue: {
      type: [Boolean, String, Number],
      default: true,
    },
    falseValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    checked: Boolean,
    id: String,
    name: String,
    round: Boolean,
    disabled: Boolean,
    indeterminate: Boolean,
  },
  emits: ['update:modelValue'],
  setup: (props, { emit }) => {
    const computedValue = computed({
      get(): boolean | string | number | (string | number)[] | undefined {
        return props.modelValue;
      },
      set(value: boolean | string | number | (string | number)[] | undefined) {
        emit('update:modelValue', value);
      },
    });
    return { computedValue };
  },
});
