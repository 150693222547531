import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { defineComponent } from 'vue';

import ProducerProjects from '@/views/ProducerProjects.vue';
import ProducerView from '@/views/ProducerView.vue';
import OperatorScreen from '@/views/OperatorScreen.vue';
import LoginDirector from '@/views/LoginDirector.vue';
import RolesView from '@/views/RolesView.vue';
import VideoEditor from '@/views/VideoEditorView.vue';

import LandingPage from '@/views/LandingPage.vue';

import TestMock from '@/views/tests/mock.vue';
import testRoutes from './testRoutes';

import { api } from '@/utils/api';
import appEvents from '@/utils/events';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: LandingPage,
  },
  {
    path: '/producer-projects',
    name: 'producer-projects',
    component: ProducerProjects,
  },
  {
    path: '/director',
    name: 'director',
    meta: {
      auth: 'director',
    },
    component: ProducerView,
  },
  {
    path: '/operator/:camera?',
    name: 'operator',
    component: OperatorScreen,
    meta: {
      auth: 'operator',
    },
  },
  {
    path: '/roles',
    name: 'roles',
    component: RolesView,
  },

  // tests
  ...testRoutes,

  {
    path: '/video-editor/:projectId?',
    name: 'video-editor',
    component: VideoEditor,
  },
  {
    path: '/login',
    name: 'login-director',
    component: LoginDirector,
    beforeEnter: async (to) => {
      if (!to.query.token) return '/';
      if (process.env.VUE_APP_AUTH !== '' && to.query.token === 'env') {
        to.query.token = process.env.VUE_APP_AUTH || '';
      }
      const am = await api().setAppAuth(to.query.token as string);
      if (am === null) return '/bad-login';
      return '/roles';
    },
  },
  {
    path: '/qr/:code',
    name: 'qrcode-auth',
    beforeEnter: async (to) => {
      const data = await api().checkQrCode(to.params.code as string);
      if (data === null) {
        api().resetProjectAuth();
        return '/bad-qr-code';
      }
      api().setProjectAuth(`${data.id}:${data.auth}`, data.camera);
      return {
        name: 'operator',
        params: {
          camera: data.camera,
        },
      };
    },
    meta: {
      auth: 'none',
    },
    component: defineComponent({}), // чисто что бы было
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const savedAuth = JSON.parse(window.localStorage.getItem('app.auth') || 'null');

  appEvents.emit('app:checkVersion');
  api().refreshDebugOptions();

  switch (to.meta.auth) {
    case 'director':
      if (api().isFullAuthorized()) return next();
      else return next({ name: 'home' });
      break;
    case undefined:
      if (!savedAuth && to.path !== '/login') {
        if (to.path === '/') return next();
        return next({ name: 'home' });
      }
      return next();
      break;
    case 'none':
    case 'operator':
      next();
      return;
    default:
      throw new Error(`Unknown auth type: ${to.meta.auth}`);
      return;
  }
});

export default router;
