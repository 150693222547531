
import { defineComponent, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

export default defineComponent({
  name: 'UserModal',
  props: {
    showModal: {
      default: false,
      type: Boolean,
    },
    position: {
      default: 'center',
      type: String,
    },
  },
  setup(props, { emit }) {
    const modalContent = ref(null);

    onClickOutside(modalContent, () => emit('close'));

    return {
      modalContent,
    };
  },
});
