
import { computed, defineComponent, ref } from 'vue';
import Icon from '../icon/Icon.vue';

export default defineComponent({
  props: {
    max: {
      type: Number,
      default: 2,
    },
    min: {
      type: Number,
      default: 1,
    },
    step: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: Number,
      default: 1,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const currentPosition = computed(() => {
      const percent = (100 * (value.value - props.min || 0)) / (props.max - props.min);
      if (percent > 50) return `top: ${100 - percent}%`;
      return `bottom: ${percent}%`;
    });

    const value = computed({
      get() {
        return Number(props.modelValue?.toFixed(1));
      },
      set(value) {
        emit('update:modelValue', +value);
      },
    });

    const plusValue = () => {
      if (value.value < props.max) {
        value.value = +value.value + props.step;
      }
    };

    const minusValue = () => {
      if (value.value && value.value > props.min) {
        value.value = +value.value - props.step;
      }
    };

    return {
      value,
      plusValue,
      minusValue,
      currentPosition,
    };
  },
  components: { Icon },
});
