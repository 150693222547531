import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b751572"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-menu" }
const _hoisted_2 = { class: "modal-menu__list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "modal-menu__bottom-content" }
const _hoisted_5 = { class: "modal-menu__version" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_AppVersion = _resolveComponent("AppVersion")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    showModal: _ctx.isShowModal,
    position: "fullscreen"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(['modal-menu__item', { disabled: item.disabled }]),
              key: index,
              onClick: item.click
            }, [
              _createVNode(_component_Icon, {
                icon: item.icon,
                class: "modal-menu__icon"
              }, null, 8, ["icon"]),
              _createElementVNode("span", null, _toDisplayString(item.text), 1)
            ], 10, _hoisted_3))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createTextVNode("Версия приложения  "),
            _createVNode(_component_AppVersion)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["showModal"]))
}