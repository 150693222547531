
import { defineComponent, onBeforeUnmount, ref } from 'vue';
import Icon from '../icon/Icon.vue';
import { useThrottleFn } from '@vueuse/core';

export default defineComponent({
  inheritAttrs: false,
  name: 'ButtonNLP',
  props: {
    text: {
      default: '',
      type: String,
    },
    size: {
      default: 'xl',
      type: String,
    },
    type: {
      default: 'primary',
      type: String,
    },
    icon: {
      default: '',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const timeout = ref<number | null>(null);

    const click = useThrottleFn(() => {
      emit('click');
    }, 500);

    onBeforeUnmount(() => {
      if (timeout.value) clearInterval(timeout.value);
    });

    return {
      click,
    };
  },
  components: { Icon },
});
