import { Ref, h, ref } from 'vue';

export interface Position {
  x: number;
  y: number;
}

export function useSize() {
  const setVarWindowHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--window-height', `${window.innerHeight}px`);

    addEventListener('resize', () => {
      doc.style.setProperty('--window-height', `${window.innerHeight}px`);
    });
  };
  return {
    setVarWindowHeight,
  };
}
