
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'TestQRCode',
  components: {},
  setup() {
    const route = useRoute();
    const mockText = ref('');

    onMounted(async () => {
      mockText.value = '' + route.meta.mock;
      console.log('test mock', mockText.value);
    });

    return { mockText };
  },
});
