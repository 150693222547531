
import { computed, defineComponent, ref, toRefs } from 'vue';

export default defineComponent({
  props: {
    percent: {
      type: Number,
      default: 0,
    },
    percentPosition: {
      type: String,
      default: 'right',
    },
    type: {
      default: 'line',
      type: String,
    },
    text: {
      default: '1',
      type: String,
    },
  },
  setup(props) {
    const { percent } = toRefs(props);
    let circle = ref();
    const percentCircle = computed(() => {
      let val: number = percent.value;
      let r: number = +(circle.value?.getAttribute('r') || 0);
      let c = Math.PI * (r * 2);

      if (val < 0) {
        val = 0;
      }
      if (val > 100) {
        val = 100;
      }

      let pct = ((100 - val) / 100) * c;
      return pct;
    });
    return {
      percentCircle,
      circle,
    };
  },
});
