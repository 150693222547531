import EventEmitter from 'eventemitter3';
import { v4 as uuidv4 } from 'uuid';

export enum NLPEditingEventType {
  None = 0,
  Start, // мотор !!!
  Stop, // стоп, снято
  TakeVideo, // переключение (эффекты переключения тут же)
  TakeAudio, // переключение звуковой дороги
  TextOverlay, // вывод текста поверх видео
  EffectOverlay, // вывод эфекта поверх видео
  MediaOverlay, // вывод медиа (видео, картинка) поверх видео
  VideoInsert, // вставка отрезка видео
  AudioInsert, // вставка звуковой дорожки
  OverlayTransform, // изменение физических размеров
  OverlayModify, // изменение цвета, содержимого и т.д.
  VideoTransform, // кроп-зум видео
  VideoModify, // яркость, контрастность, ...
  AudioModify, // громкость, муть, ...
  TakeBlack, // черный экран
  TakeMute, // пустая звуковая дорога
}

const NLPEditingEventTypeNames = Object.values(NLPEditingEventType).filter((v) => typeof v === 'string');

interface NLPObjectPosition {
  x: number;
  y: number;
  z?: number; // для выбора слоя
}

interface NLPObjectDimensions {
  width: number;
  height: number;
}

type NLPObjectPlacement = NLPObjectPosition & NLPObjectDimensions;

interface NLPEditingEventBase {
  order: number; // порядок применения события
  dt: number; // время применения события
  type: NLPEditingEventType; // тип события
  cloudId?: string; // id записи при заливке в облако
  opaque?: object; // пользовательсие данные
}

interface NLPEditingEventTake {
  camera: number;
}

interface NLPEditingEventTextOverlay {
  text: string;
  position: NLPObjectPlacement; // отдельно от стиля
  style: CSSStyleDeclaration;
}

//prettier-ignore
export type NLPEditingEvent = NLPEditingEventBase &
                              Partial<NLPEditingEventTake> &
                              Partial<NLPEditingEventTextOverlay>;

type NLPEditingListType = Array<NLPEditingEvent>;

interface NLPEventRangeBase {
  start: number;
  stop: number;
  type: NLPEditingEventType;
  camera: number;
  cloudId?: string; // id записи при заливке в облако
  opaque?: object; // пользовательсие данные
  id?: string;
}

export type NLPEditingRange = NLPEventRangeBase & Partial<NLPEditingEventTextOverlay>;

export type NLPEditingRanges = Array<NLPEditingRange>;

export class NLPEditingList extends EventEmitter {
  project_id: string;
  private dt = 0;
  private debug = false;
  private startTime = 0;
  list: NLPEditingListType = [];
  rangeList: Array<NLPEditingRanges> = [];

  constructor(project_id: string, ranges: NLPEditingRanges = [], isEmptyList?: boolean, startDt: number | null = null) {
    super();
    this.project_id = project_id;
    this.dt = Date.now();
    if (startDt && !ranges.length) this.startTime = startDt;
    if (ranges.length) {
      this.convertToEvents(ranges);
      // this.rangeList = ranges;
    }
    if (isEmptyList) {
      this.start();
      this.stop();
      this.generateCloudIds();
    }
  }

  get started(): boolean {
    return this.list.length > 0 && this.list[0].type === NLPEditingEventType.Start;
  }

  get stopped(): boolean {
    return this.list.length > 1 && this.list[this.list.length - 1].type === NLPEditingEventType.Stop;
  }

  private pushList(...elements: Array<NLPEditingEvent>) {
    if (this.debug === true) {
      elements.forEach((el) => {
        console.log('EL:push', el);
      });
    }
    this.list.push(...elements);
  }

  clear() {
    this.list.splice(0, this.list.length);
  }

  start(camera?: number, now?: number) {
    if (this.started) throw new Error('List alredy started');
    if (this.stopped) throw new Error('List is stopped');
    if (now === undefined) now = Date.now();
    this.pushList({
      type: NLPEditingEventType.Start,
      order: 0,
      dt: now,
    });
    this.startTime = now;
    if (camera !== undefined) {
      this.pushList({
        type: NLPEditingEventType.TakeVideo,
        order: 1,
        dt: now,
        camera: camera,
      });
    }
    this.emit('start', this, now, camera);
  }

  stop(now?: number) {
    if (!this.started) throw new Error('List not started');
    if (this.stopped) throw new Error('List alredy stopped');
    if (now === undefined) now = Date.now();
    this.appendEvent({
      type: NLPEditingEventType.Stop,
      order: 0,
      dt: now,
    });
    this.emit('stop', this, now);
  }

  appendEvent(event: NLPEditingEvent): NLPEditingList {
    console.log(event, 'event');
    if (!event.dt) throw new Error('appendEvent: dt is undefined');
    const lastEl = this.list[this.list.length - 1];
    if (lastEl !== undefined) event.order = lastEl.order + 1;
    else event.order = 0;
    this.pushList(event);
    this.emit('append', this, event);
    return this;
  }

  insertEvent(event: NLPEditingEvent, index: number): NLPEditingList {
    if (!event.dt) throw new Error('insertEvent: dt is undefined');
    this.list.splice(index, 0, event);
    this.updateOrder();
    this.emit('insert', this, event, index);
    return this;
  }

  deleteEvent(index: number): NLPEditingList {
    const oldEvent = this.list.splice(index, 1);
    this.updateOrder();
    this.emit('delete', this, index, oldEvent);
    return this;
  }

  deleteAndReturnEvent(index: number): NLPEditingEvent | null {
    const res = this.list[index];
    if (res === undefined) return null;
    this.list.splice(index, 1);
    this.updateOrder();
    this.emit('delete', this, index, res);
    return res;
  }

  private updateOrder() {
    this.list.forEach((ev, idx) => (ev.order = idx));
  }

  exportForCloud() {
    return this.list.map((ev: NLPEditingEvent) => {
      const { cloudId, order, dt, type, ...action } = ev;
      return {
        cloudId: cloudId,
        order: order,
        dt: dt,
        type: type,
        action: action,
      };
    });
  }

  deleteAllEvents() {
    this.clear();
    this.emit('deleteAll', this);
  }

  updateIdsFromCloud(idList: Array<{ o: number; id: string }>) {
    if (this.list.length !== idList.length) throw new Error('Lists are different length');
    idList.forEach((r) => {
      const ev = this.list.find((e) => e.order === r.o);
      if (ev !== undefined) ev.cloudId = r.id;
    });
  }

  exportForPeer(camera: number): Array<Array<number>> {
    if (this.dt === 0) throw new Error('No start event in list');
    if (this.list.length === 0) throw new Error('Empty list');
    const res: Array<Array<number>> = [];

    let activeDuration: Array<number> | null = null;
    let activeMask = 0; // битмаск
    this.list.forEach((ev) => {
      if (ev.type === NLPEditingEventType.Stop) {
        if (activeDuration !== null && ev.dt !== activeDuration[0]) {
          activeDuration[1] = ev.dt;
          res.push(activeDuration);
          activeDuration = null;
        }
        return;
      }
      if (ev.type === NLPEditingEventType.TakeVideo || ev.type === NLPEditingEventType.TakeAudio) {
        if (ev.camera === camera) {
          activeMask |= 1 << ev.type;
        } else {
          activeMask &= ~(1 << ev.type);
        }
      }
      if (activeDuration === null && activeMask !== 0) {
        activeDuration = [ev.dt, 0];
      } else if (activeDuration !== null && activeMask === 0) {
        activeDuration[1] = ev.dt;
        res.push(activeDuration);
        activeDuration = null;
      }
    });

    return res;
  }

  exportAsRanges(relative = true): Array<Array<any>> {
    if (!this.started) throw new Error('List not started');
    if (!this.stopped) throw new Error('List not stopped');
    const res: Array<Array<any>> = [];
    const RL = this.exportAsRangesInternal();

    const rel = relative ? this.startTime : 0;

    RL.forEach((list, idx) => {
      const L: Array<any> = list.map((R) => ({
        start: R.start - rel,
        stop: R.stop - rel,
        type: NLPEditingEventTypeNames[R.type],
        id: R.cloudId,
        camera: R.camera,
        opaque: R.opaque,
        text: R.text,
        position: R.position,
        style: R.style,
      }));
      res[idx] = L;
    });

    return res;
  }

  private exportAsRangesInternal(): Array<NLPEditingRanges> {
    if (this.rangeList.length !== 0) return this.rangeList;

    const result: Array<NLPEditingRanges> = [];
    const activeEventType: Array<NLPEditingRange> = [];

    this.list.forEach((ev) => {
      if (ev.type === NLPEditingEventType.Stop) {
        activeEventType.forEach((R) => {
          R.stop = ev.dt;
        });
        return;
      }

      if (ev.camera === undefined) return;

      if (result[ev.camera] === undefined) result[ev.camera] = [];

      const ae = activeEventType[ev.type];
      if (ae !== undefined) {
        // существующее событие, меняем камеру
        ae.stop = ev.dt;
        result[ae.camera].push(ae);
      }
      activeEventType[ev.type] = { camera: ev.camera, start: ev.dt, stop: 0, type: ev.type, cloudId: ev.cloudId };
    });

    activeEventType.forEach((R) => {
      result[R.camera].push(R);
    });

    result.forEach((Rlist) => {
      Rlist.sort((RA, RB) => {
        return RA.start - RB.start;
      });
    });

    return result;
  }

  convertToRanges() {
    if (!this.started) throw new Error('List not started');
    if (!this.stopped) throw new Error('List not stopped');
    if (this.list.some((el) => el.cloudId === undefined)) throw new Error('List without cloud ids');

    this.rangeList = this.exportAsRangesInternal();
  }

  convertToEvents(ranges: NLPEditingRanges) {
    const events: NLPEditingListType = [];
    this.startTime = ranges[0].start ? ranges[0].start : this.startTime;

    events.push({
      type: NLPEditingEventType.Start,
      order: 0,
      dt: ranges[0].start,
      cloudId: uuidv4(),
    });
    ranges.forEach((range, index) => {
      events.push({
        camera: range.camera,
        type: NLPEditingEventType.TakeVideo,
        order: index + 1,
        dt: range.start,
        cloudId: range.id || uuidv4(),
      });
    });
    events.push({
      type: NLPEditingEventType.Stop,
      order: ranges.length + 1,
      dt: ranges[ranges.length - 1].stop,
      cloudId: uuidv4(),
    });
    this.list = events;
  }

  generateCloudIds() {
    if (this.list.some((el) => el.cloudId !== undefined)) throw new Error('Cloud ids already generated');
    this.list.forEach((el) => {
      el.cloudId = uuidv4();
    });
  }

  private getCameraRangeById(id: string, cameraRanges: NLPEditingRanges | undefined): NLPEditingRange | null {
    if (cameraRanges === undefined) return null;
    return cameraRanges.find((R) => R.cloudId === id) || null;
  }

  getRangeById(id: string, camera?: number): NLPEditingRange | null {
    if (this.rangeList.length === 0) throw new Error('No ranges list');
    if (camera !== undefined && camera > 0) return this.getCameraRangeById(id, this.rangeList[camera]);
    for (const cameraRanges of this.rangeList) {
      const res = this.getCameraRangeById(id, cameraRanges);
      if (res !== null) return res;
    }
    return null;
  }

  sortRanges() {
    this.rangeList.forEach((cameraRanges) => {
      cameraRanges.sort((RA, RB) => RA.start - RB.start);
    });
  }

  updateRangeTime({
    id,
    camera,
    start,
    stop,
    relative = true,
  }: {
    id: string;
    camera?: number | undefined;
    start: number;
    stop: number;
    relative: boolean;
  }): boolean {
    const R = this.getRangeById(id, camera);
    if (R === null) return false;
    if (relative) {
      start += this.startTime;
      stop += this.startTime;
    }
    if (R.start === start && R.stop === stop) return false;
    R.start = start;
    R.stop = stop;
    this.sortRanges();
    this.emit('updateRange', this, id, R.camera, R);
    return true;
  }

  deleteRange(id: string, camera?: number): boolean {
    const R = this.getRangeById(id, camera);
    if (R === null) return false;
    const idx = this.rangeList[R.camera].indexOf(R);
    if (idx === -1) return false;
    this.rangeList[R.camera].splice(idx, 1);
    this.emit('deleteRange', this, id, R.camera, R);
    return true;
  }

  addRange(R: NLPEditingRange, relative = false) {
    if (this.rangeList[R.camera] === undefined) {
      this.rangeList[R.camera] = [];
    }
    const list = this.rangeList[R.camera];
    R.cloudId = R.id;
    // R.cloudId = uuidv4();
    if (relative) {
      R.start += this.startTime;
      R.stop += this.startTime;
    }

    list.push(R);
    list.sort((RA, RB) => RA.start - RB.start);
    this.emit('addRange', this, R);
  }

  dump() {
    console.log(this.list);
  }
}

function getBlackEventType(type: NLPEditingEventType): NLPEditingEventType {
  switch (type) {
    case NLPEditingEventType.TakeVideo:
      return NLPEditingEventType.TakeBlack;
    case NLPEditingEventType.TakeAudio:
      return NLPEditingEventType.TakeMute;
  }
  return NLPEditingEventType.None;
}

export function flattenRangesList(list: Array<NLPEditingRanges>, type: NLPEditingEventType): NLPEditingRanges {
  const result: NLPEditingRanges = [];

  const flatList: NLPEditingRanges = list.reduceRight((A, L) => {
    L.forEach((R) => {
      if (R.type === type) A.push(R);
    });
    return A;
  }, []);

  flatList.sort((A, B) => {
    if (A.start === B.start) return A.camera - B.camera;
    return A.start - B.start;
  });

  if (flatList.length < 2) return flatList;

  flatList.forEach((R, Ri) => {
    flatList[Ri] = Object.assign({}, R);
  });

  let cur = flatList.shift();

  const blackType = getBlackEventType(type);

  while (flatList.length > 0) {
    const next = flatList.shift();
    if (next === undefined || cur === undefined) break;

    if (next.start < cur.start) {
      if (next.camera > cur.camera) {
        if (next.stop < cur.stop) continue; // событие поглащается
        next.start = cur.stop;
      } else {
        cur.stop = next.start; // следующее событие перекрывает текущее
      }
    }

    result.push(cur);

    if (blackType !== NLPEditingEventType.None && next.start > cur.stop) {
      // следующее событие идет через "черноту"
      result.push({
        type: blackType,
        start: cur.stop,
        stop: next.start,
        camera: 0,
      });
    }

    cur = next;
  }

  if (cur !== undefined) result.push(cur);

  return result;
}
