type NLPTimeSyncTxMethod = (data: string) => boolean;

enum NLPTimeSyncCommand {
  None,
  Sample,
  Reply,
}

interface NLPTimeSyncSample {
  iteration: number;
  tx_time: number;
  rx_time: number;
  remote_time: number;
}

export class NLPTimeSync {
  private peerTx: NLPTimeSyncTxMethod;
  private id: string;
  private iteration = 0;
  private samples: Array<NLPTimeSyncSample> = [];
  private remote_id = '';
  private remote_now = 0;
  private tx_timer = -1;

  rtt = 0;
  remote_time = 0;
  local_time_diff = 0;

  constructor(peerTx: NLPTimeSyncTxMethod, initialDelay: number) {
    this.id = Math.random().toString(36).substring(3);
    this.peerTx = peerTx;
    this.sendSample = this.sendSample.bind(this);
    this.tx_timer = window.setTimeout(this.sendSample, initialDelay);
  }

  reset(restart: boolean) {
    this.samples.splice(0, this.samples.length);
    this.rtt = 0;
    this.iteration = 0;
    this.remote_id = '';
    this.remote_now = 0;
    this.remote_time = 0;
    this.local_time_diff = 0;
    if (this.tx_timer !== -1) {
      window.clearTimeout(this.tx_timer);
      this.tx_timer = -1;
    }
    if (restart === true) this.tx_timer = window.setTimeout(this.sendSample, 500);
  }

  private sendSample() {
    if (this.samples.length > 10) this.samples.shift();

    ++this.iteration;

    const now = Date.now();
    const sample: NLPTimeSyncSample = {
      iteration: this.iteration,
      tx_time: now,
      rx_time: 0,
      remote_time: 0,
    };

    this.tx_timer = window.setTimeout(this.sendSample, this.samples.length > 3 ? 500 : 200);

    const tx_sample = [NLPTimeSyncCommand.Sample, this.id, this.iteration, now];
    if (this.peerTx(JSON.stringify(tx_sample)) === false) return;
    this.samples.push(sample);
  }

  procedRemotePacket(data: string): boolean {
    const [cmd, remote_id, iteration, remote_now] = JSON.parse(data);
    if (!cmd || !remote_id || iteration === undefined || remote_now === undefined) return false;
    if (this.remote_id === '') this.remote_id = remote_id;
    else if (this.remote_id !== remote_id) return false;

    const now = Date.now();
    this.remote_now = remote_now;

    if (cmd === NLPTimeSyncCommand.Sample) {
      const tx_reply = [NLPTimeSyncCommand.Reply, this.id, iteration, now];
      this.peerTx(JSON.stringify(tx_reply));
    } else if (cmd === NLPTimeSyncCommand.Reply) {
      const sample = this.samples.find((s) => s.iteration === iteration);
      if (sample !== undefined) {
        sample.rx_time = now;
        sample.remote_time = remote_now;
        return this.calculateDelay(sample);
      }
    }
    return false;
  }

  private calculateDelay(sample: NLPTimeSyncSample): boolean {
    if (this.samples.length < 3) return false;

    const rtt_array: Array<number> = this.samples.reduce((A: Array<number>, s) => {
      if (s.rx_time > 0) {
        A.push(s.rx_time - s.tx_time);
      }
      return A;
    }, []);
    if (rtt_array.length < 3) return false;

    //prettier-ignore
    const rtt_mean =
      Math.round(
          rtt_array.reduce((res, rtt) => {
          res += rtt;
          return res;
        }, 0) / rtt_array.length
      );

    this.rtt = rtt_mean;

    this.remote_time = Math.round(this.remote_now + rtt_mean / 2);

    this.local_time_diff = sample.rx_time - this.remote_time;

    return true;
  }
}
