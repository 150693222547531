
import { defineComponent, onMounted } from 'vue';

//import { NLPMediaRecorder } from 'src/utils/mediarecorder';

export default defineComponent({
  name: 'TestRecorder',
  components: {},
  setup() {
    onMounted(async () => {
      console.log('test recorder');

      //const rec=new NLPMediaRecorder('test');
      //await rec.open(Date.now());

      //rec.test();

      /*
      const stream1=await navigator.mediaDevices.getUserMedia({
        video: {
          width: 1280,
          height: 720,
        },
        audio: false,
      });
      */
    });

    return {};
  },
});
