import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ae69158"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "test" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, "Local " + _toDisplayString(_ctx.local_time) + " Remote " + _toDisplayString(_ctx.remote_time), 1),
    _createElementVNode("div", null, "HQ video " + _toDisplayString(_ctx.hq_video_info), 1),
    _createElementVNode("div", null, [
      _createElementVNode("video", {
        ref: "local_video",
        style: _normalizeStyle([{"width":"288px","height":"512px","padding":"5px","border":"3px solid yellow","display":"inline-block"}, { 'border-color': _ctx.border_color }]),
        autoplay: "",
        muted: "",
        controls: ""
      }, null, 4)
    ]),
    _createElementVNode("div", {
      class: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cmd_stop && _ctx.cmd_stop(...args)))
    }, "Stop")
  ]))
}