
import { defineComponent, onMounted, ref } from 'vue';

import { NLPWebRTCConnector, NLPRtcConnectionSide } from '@/utils/webrtc';

export default defineComponent({
  name: 'TestWebRTC',
  components: {},
  setup() {
    const video1 = ref<HTMLVideoElement>();
    const video2 = ref<HTMLVideoElement>();
    const video3 = ref<HTMLVideoElement>();

    onMounted(async () => {
      console.log('test webrtc');

      const conn1 = new NLPWebRTCConnector(NLPRtcConnectionSide.Receiver, 'd', 'o1');
      await conn1.create();
      console.log(conn1);

      const stream1 = await navigator.mediaDevices.getUserMedia({
        video: {
          width: 1280,
          height: 720,
        },
        audio: false,
      });

      const stream2 = await navigator.mediaDevices.getUserMedia({
        video: {
          width: 128,
          height: 72,
        },
        audio: false,
      });

      if (video3.value) video3.value.srcObject = stream1;
      if (video1.value) video1.value.srcObject = stream2;

      const conn2 = new NLPWebRTCConnector(NLPRtcConnectionSide.Sender, 'o1', 'd');
      await conn2.create(stream2);
      console.log(conn2);
      if (video2.value) video2.value.srcObject = await conn1.getReceiveStream();
    });

    return {
      video1,
      video2,
      video3,
    };
  },
});
