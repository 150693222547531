import { Commit } from 'vuex';
import { api } from '@/utils/api';

interface Project {
  image: string;
  duration: number;
  id: string;
}

export default {
  async createProject({ commit }: { commit: Commit }) {
    const data = await api().createProject({
      name: `project-${Date.now()}`,
    });
    api().setProjectAuth(`${data.id}:${data.auth}`, 1);
    commit('setProjectAuth', data.id);
  },

  async getProjectAuth({ commit }: { commit: Commit }, data: string) {
    commit('setProjectAuth', data);
  },

  async getProjects({ commit }: { commit: Commit }) {
    const data = await api().get('/api/projects');
    commit('setProjects', data);
  },

  async updateProject({ commit, state }: { commit: Commit; state: any }, params: Project) {
    try {
      const { id: projectId } = params;
      const data = await api().post(`/api/project/${projectId ? projectId : state.currentProjectId}`, params);
      commit('setProject', params);
    } catch (error) {
      console.log(error);
    }
  },

  async deleteProject({ commit }: { commit: Commit }, projectId: string) {
    try {
      const data = await api().delete(`/api/project/${projectId}`);
      commit('deleteProject', data.project_id);
    } catch (error) {
      console.log(error);
    }
  },

  async copyProject({ commit }: { commit: Commit }, projectId: string) {
    try {
      const data = await api().post(`/api/project-clone/${projectId}`, {});
      commit('copyProject', { cloneProject: data, projectId });
    } catch (error) {
      console.log(error);
    }
  },
};
