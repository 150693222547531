import { MutationTree } from 'vuex';
import { StateProject } from './state';
import { NLPEditingList } from '@/utils/editinglist';

const mutations: MutationTree<StateProject> = {
  setVideos(state, videos: string[]) {
    state.videos = videos;
  },
  setList(state, list: NLPEditingList) {
    state.list = list;
  },
  setCurrentTime(state, currentTime: number) {
    state.currentTime = currentTime;
  },

  setDuration(state, currentTime: number) {
    state.duration = currentTime;
  },
};

export default mutations;
