
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import { QrStream } from 'vue3-qr-reader';
// import  from '../../../dist/vue3-qr-reader.common'

// import { init, decompress } from '@bokuweb/zstd-wasm';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ReqderQr',
  components: {
    QrStream,
  },
  setup(props, { emit }) {
    const result: any = ref('');
    const onDecode = async (str: any) => {
      emit('decode', str);
    };

    const router = useRouter();
    const store = useStore();
    const timeout: any = ref(null);

    onMounted(() => {
      timeout.value = setTimeout(() => {
        router.push({ path: '/roles' });
        store.dispatch('modals/getIsShowModalError', { value: true, text: 'QR код не распознан' });
      }, 10 * 1000 * 60);
    });

    onBeforeUnmount(async () => {
      clearTimeout(timeout.value);
    });

    const onInit = (promise: any) => {
      promise.then(console.log).catch(console.error);
    };

    return {
      onDecode,
      onInit,
      result,
    };
  },
});
