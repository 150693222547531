import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([`button-rec button-rec_type-${_ctx.type}`, { disabled: _ctx.disabled }, { animate: _ctx.animateButton }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickBtn && _ctx.clickBtn(...args)))
  }, [
    (_ctx.type === 'stop')
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          icon: "stop"
        }))
      : _createCommentVNode("", true)
  ], 2))
}