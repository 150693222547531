import { Commit } from 'vuex';
import { NLPUploadProgress } from './state';

export default {
  updateLocalUploadState({ commit }: { commit: Commit }, S: NLPUploadProgress) {
    commit('setLocalUploadState', S);
  },

  updateRemoteUploadState({ commit }: { commit: Commit }, S: NLPUploadProgress) {
    commit('setRemoteUploadState', S);
  },

  updateLocalUploadProgress(
    { commit }: { commit: Commit },
    data: { camera: number; value: number; active: boolean; error: boolean }
  ) {
    commit('setLocalUploadProgress', data);
  },

  updateRemoteUploadProgress(
    { commit }: { commit: Commit },
    data: { camera: number; value: number; active: boolean; error: boolean }
  ) {
    commit('setRemoteUploadProgress', data);
  },

  clearAll({ commit }: { commit: Commit }) {
    commit('clearAll');
  },

  renderProgress({ commit }: { commit: Commit }, value: number) {
    commit('setRenderProgress', value);
  },
};
