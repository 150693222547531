
import { defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
// import { api } from '@/utils/api';
import Icon from '@/components/common/icon/Icon.vue';
import { useStore } from '@/store';

export default defineComponent({
  name: 'LoginDirector',
  components: { Icon },
  setup() {
    const router = useRouter();
    // const route = useRoute();
    const store = useStore();

    onMounted(() => {
      if (store.state.auth.loggedUser) {
        router.push('producer-projects');
      }
    });
    const login = async () => {
      window.location.replace('/api/login/google');
    };
    // checkUser();
    return {
      login,
    };
  },
});
