import { onMounted, onBeforeUnmount, ref } from 'vue';
import { NLPDestroyList } from '@/utils/destroyer';

export function useCommon() {
  const memory = ref<number | undefined>(0);
  const destroyers: NLPDestroyList = new NLPDestroyList();

  async function getFreeMemory() {
    const { quota, usage } = await navigator.storage.estimate();
    if (quota && usage) memory.value = Math.round((quota - usage) / 1e9);
  }

  //при старте обновляем сразу
  getFreeMemory();

  onMounted(() => {
    destroyers.destroyInterval(window.setInterval(getFreeMemory, 10000), 'update-local-time');
  });

  onBeforeUnmount(async () => {
    destroyers.destroyAll();
  });

  return {
    memory,
  };
}
