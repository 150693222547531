import { MutationTree } from 'vuex';
import { State } from './state';
import { NLPLoggedUser } from '@/utils/api';

const mutations: MutationTree<State> = {
  setLoggedUser(state: State, user: NLPLoggedUser) {
    if (user === null) state.loggedUser = null;
    else {
      state.loggedUser = {
        name: user.name,
        admin: user.admin,
      };
    }
  },
  setOperatorCamera(state: State, camera: number) {
    state.operatorCamera = camera;
  },
};

export default mutations;
