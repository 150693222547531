import appEvents from '@/utils/events';

//eslint-disable-next-line
export type NLPDestroyCallback = (...args: Array<any>) => void | Promise<void>;

export class NLPResourceHolder {
  destroyCb: NLPDestroyCallback;
  id?: string;
  thisArg?: object | null;
  //eslint-disable-next-line
  destroyArgs?: Array<any>;

  //eslint-disable-next-line
  constructor(destroyCb: NLPDestroyCallback, id?: string, thisArg?: object | null, ...destroyArgs: Array<any>) {
    this.destroyCb = destroyCb;
    this.id = id;
    this.thisArg = thisArg;
    this.destroyArgs = destroyArgs;
  }

  async destroy() {
    if (this.thisArg !== undefined) {
      if (Array.isArray(this.destroyArgs)) await this.destroyCb.apply(this.thisArg, this.destroyArgs);
      else await this.destroyCb.call(this.thisArg);
    } else {
      await this.destroyCb();
    }
  }
}

function destroyTimerCb(timer: number) {
  window.clearTimeout(timer);
}

function destroyIntervalCb(interval: number) {
  window.clearTimeout(interval);
}

//eslint-disable-next-line
function destroyEventCb(event: string, cb: any) {
  appEvents.off(event, cb);
}

export class NLPDestroyList extends Array<NLPResourceHolder> {
  findById(id: string) {
    return this.find((D) => D.id === id);
  }

  destroyById(id: string): boolean {
    const Didx = this.findIndex((D) => D.id === id);
    if (Didx === -1) return false;
    const [D] = this.splice(Didx, 1);
    if (D !== undefined) D.destroy();
    return true;
  }

  deleteById(id: string): NLPResourceHolder | null {
    const Didx = this.findIndex((D) => D.id === id);
    if (Didx === -1) return null;
    const [D] = this.splice(Didx, 1);
    return D;
  }

  async destroyAll() {
    // удаляем в обратном порядке
    for (let D = this.pop(); D !== undefined; D = this.pop()) {
      await D.destroy();
    }
  }

  destroyTimer(timer: number, id?: string) {
    this.push(new NLPResourceHolder(destroyTimerCb, id, null, timer));
  }

  destroyInterval(interval: number, id?: string) {
    this.push(new NLPResourceHolder(destroyIntervalCb, id, null, interval));
  }

  //eslint-disable-next-line
  destroyClass(cl: any, method: string, id?: string, ...args: Array<any>) {
    this.push(new NLPResourceHolder(cl[method], id, cl, ...args));
  }

  //eslint-disable-next-line
  destroyFunc(func: NLPDestroyCallback, id?: string, ...args: Array<any>) {
    this.push(new NLPResourceHolder(func, id, null, args));
  }

  //eslint-disable-next-line
  destroyEvent(event: string, cb: any, id?: string) {
    this.push(new NLPResourceHolder(destroyEventCb, id, null, event, cb));
  }
}
