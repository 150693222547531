import { MutationTree } from 'vuex';
import { Modals } from './state';

const mutations: MutationTree<Modals> = {
  setIsShowModalError(state, { value, text }) {
    state.isShowModalError = value;
    state.errorText = !value ? '' : text;
  },
  setIsShowModalSuccess(state, value) {
    state.isShowModalSuccess = value;
  },
  setIsShowPreloader(state: any, { value }: any) {
    state.isShowPreloader = value;
  },
};

export default mutations;
