export const convertTime = (ms: number) => {
  const seccond = ms / 1000;
  const minutes = seccond > 60 ? Math.floor(seccond / 60) : 0;
  const seconds = seccond > 60 ? seccond - Math.floor(seccond) * 60 : Math.floor(seccond);

  return {
    s: seconds < 10 ? `0${seconds}` : seconds,
    m: minutes < 10 ? `0${minutes}` : minutes,
    ms: seccond * 1000,
  };
};
