
import { defineComponent } from 'vue';
import Icon from '@/components/common/icon/Icon.vue';
import { useThrottleFn } from '@vueuse/core';

export default defineComponent({
  inheritAttrs: false,
  name: 'CircleIconButton',
  props: {
    text: {
      default: '',
      type: String,
    },
    type: {
      default: 'secondary',
      type: String,
    },
    icon: {
      default: '',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const click = useThrottleFn(() => {
      emit('click');
    });
    return {
      click,
    };
  },
  components: { Icon },
});
