import { EventEmitter } from 'eventemitter3';

import { NLPWebRTCConnector } from './webrtc';

export enum NLPP2PMessageType {
  Event = 'event',
  TimeSync = 'timesync',
}

const MessageTypeStrings: Set<string> = new Set(Object.values(NLPP2PMessageType));

export type NLPParsedMessageData = Array<string | number | object | boolean | null | ArrayBuffer | Blob>;

export class NLPP2PMessage {
  static decodeString(text: string): NLPP2PMessage | null {
    try {
      if (text[0] !== '[') throw new Error('message must be array');
      const msg = JSON.parse(text);
      if (!Array.isArray(msg)) throw new Error('message must be array');
      if (msg.length < 2) throw new Error('message mus be array of 2+ elements');
      const msgType = msg.shift();
      if (!MessageTypeStrings.has(msgType)) throw new Error(`Bad message type: ${msgType}`);
      return new NLPP2PMessage(msgType, msg);
    } catch (err) {
      console.log('Bad message:', text);
      console.log('Error', err);
      return null;
    }
  }

  type: NLPP2PMessageType;
  data: NLPParsedMessageData;

  private dt: number;
  private timeout?: number;

  constructor(type: NLPP2PMessageType, data: NLPParsedMessageData, timeout?: number) {
    this.type = type;
    this.data = data;
    this.dt = Date.now();
    this.timeout = timeout;
  }

  toString(): string {
    if (this.timeout !== undefined && this.timeout > 0) {
      const now = Date.now();
      if (now - this.dt > this.timeout) return '';
    }
    return JSON.stringify([this.type, ...this.data]);
  }

  toBinary(): ArrayBuffer {
    return new ArrayBuffer(0);
  }
}

type P2PEventHandler = (msg: NLPP2PMessage) => void;

/**
 * тут делается lazy loading, так как иначе оно циклится при объявлении класса NLPP2PMessage
 */
let NLPParseP2PEvent: (msg: NLPP2PMessage) => object;

/**
 * Регистрация функции десериализации события
 * @param parser функия десериализации
 */
export function NLPregisterEventParser(parser: (msg: NLPP2PMessage) => object) {
  NLPParseP2PEvent = parser;
}

export class NLPPeerProtocol extends EventEmitter {
  // вообще свойство инициализируется в декораторе, тут убеждамеся что оно точно инициализировано
  private handlers: Map<string, P2PEventHandler> = new Map();
  private webrtc: NLPWebRTCConnector;

  constructor(webrtc: NLPWebRTCConnector) {
    super();
    this.webrtc = webrtc;
    this.handlers.set('timesync', this.ev_timesync.bind(this));
    this.handlers.set('event', this.ev_event.bind(this));
  }

  ev_timesync(msg: NLPP2PMessage): void {
    this.webrtc.timesyncMessage(msg.data[0] as string);
  }

  ev_event(msg: NLPP2PMessage): void {
    this.emit(`event:${msg.data[0] as string}`, ...msg.data.slice(1));
    /*
     * тут вариант с событием в виде объекта
     */
    if (NLPParseP2PEvent !== null) this.emit(`event=${msg.data[0] as string}`, NLPParseP2PEvent(msg));
  }

  incomeMessage(ev: MessageEvent) {
    try {
      if (typeof ev.data === 'string') {
        const msg = NLPP2PMessage.decodeString(ev.data);
        if (msg === null) return;
        const handler = this.handlers.get(msg.type);
        if (handler !== undefined) handler(msg);
      }
    } catch (err) {
      //prettier-ignore
      console.log('Can\'t parse inbound message', ev, err);
    }
  }

  sendMessage(msg: NLPP2PMessage): boolean {
    return this.webrtc.sendData(msg.toString());
  }

  emitRemote(event: NLPP2PMessage): boolean;
  emitRemote(event: string, data: string | object | number | boolean | null): boolean;
  emitRemote(event: any, data?: any) { // eslint-disable-line
    if (event instanceof NLPP2PMessage) return this.webrtc.sendData(event.toString());
    return this.webrtc.sendData(JSON.stringify(['event', event, data]));
  }
}
