
import { ToRefs, defineComponent, onMounted, ref, toRefs } from 'vue';
import vueQr from 'vue-qr/src/packages/vue-qr.vue';
//import { init, compress } from '@bokuweb/zstd-wasm';
import { useStore } from '@/store';
import { api } from '@/utils/api';

import ButtonUser from '@/components/common/buttons/Button.vue';

export default defineComponent({
  name: 'GenerateQr',
  components: {
    vueQr,
    ButtonUser,
  },
  props: {
    cam: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const sdp = ref('');
    //const compressed: any = ref(null);
    const store = useStore();
    //eslint-disable-next-line
    //const image = require('@/assets/images/qr.png');
    const image = '';

    const { cam }: ToRefs<{ cam: number }> = toRefs(props);

    const qrText = ref<string>('');
    const qrLink = ref<string>('');
    const shareSupported = ref<boolean>(typeof navigator.share === 'function');

    const share = () => {
      navigator.share({
        title: 'подключить камеру',
        url: qrLink.value,
      });
    };

    onMounted(async () => {
      //await init();
      // const encoder = new TextEncoder();
      // const buffer = encoder.encode('Hello World');

      // compressed.value = compress(buffer, 10);
      // sdp.value = compressed.value.toString();

      if (!store.state.projects.currentProjectId) return;

      const projectId = store.state.projects.currentProjectId;
      const text = await api().requestQrCode(projectId, cam.value);
      if (text !== '') qrLink.value = `${location.origin}/qr/${text}`;
    });

    return {
      sdp,
      qrText,
      qrLink,
      shareSupported,
      share,
      image,
    };
  },
});
