
import { defineComponent, onMounted, ref } from 'vue';
import ButtonUser from '@/components/common/buttons/Button.vue';
import { api } from '@/utils/api';

const detectVideoCodecs = [
  'avc1.4d002a',
  'avc1.420028',
  'avc1.420029',
  'avc1.42002a',
  'avc1.420032',
  'avc1.424028',
  'avc1.42001e',
  'av01.0.00M.08',
  'av01.0.10M.08',
  'av01.0.00H.08',
  'av01.0.10H.08',
  'hev1.1.6.L93.B0',
  'hev1.2.4.L120.B0',
  'vp8',
  'vp09.00.10.08',
];

// eslint-disable-next-line
const detectAccelerations: Array<HardwarePreference> = ['prefer-hardware', 'prefer-software'];

const latencyModes = ['quality', 'realtime'];

export default defineComponent({
  name: 'TestCodecs',
  components: {
    ButtonUser,
  },
  setup() {
    const codecList = ref<Array<string>>([]);

    const share = () => {
      if (navigator.share) {
        navigator.share({
          title: 'supported codecs',
          text: codecList.value.join('\n'),
        });
      } else {
        navigator.clipboard.writeText(codecList.value.join('\n'));
      }
    };

    onMounted(async () => {
      console.log('test codecs');
      for (const latency of latencyModes) {
        for (const codec of detectVideoCodecs) {
          for (const acceleration of detectAccelerations) {
            const config = {
              codec,
              hardwareAcceleration: acceleration,
              width: 1920,
              height: 1080,
              bitrate: 10_000_000,
              framerate: 30,
              latencyMode: latency,
            };
            // eslint-disable-next-line
            const supported = await VideoEncoder.isConfigSupported(config);
            if (supported.supported) {
              if (supported.config !== undefined) {
                // eslint-disable-next-line
                const enc = new VideoEncoder({
                  output: () => {
                    false;
                  },
                  error: (err) => {
                    console.log('encoder error', err);
                  },
                });
                enc.configure(supported.config);
                if (enc.state !== 'configured') {
                  codecList.value.push(`${codec} ${acceleration} ${latency} - no conf`);
                } else {
                  codecList.value.push(`${codec} ${acceleration} ${latency} - OK`);
                }
                enc.close();
              } else {
                codecList.value.push(`${codec} ${acceleration} ${latency} - bad conf`);
              }
            } else {
              codecList.value.push(`${codec} ${acceleration} ${latency} - no support`);
            }
          }
        }
      }
      await api().sendCodecDebug('supoort', {
        list: codecList.value,
      });
    });

    return {
      codecList,
      share,
    };
  },
});
