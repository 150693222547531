import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df6b7b76"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "circle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button", [_ctx.buttonType, { disable: _ctx.disabled }]]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickButton && _ctx.clickButton(...args)))
  }, [
    (_ctx.buttonType !== 'started')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : (_openBlock(), _createBlock(_component_Icon, {
          key: 1,
          icon: "square",
          class: "rec-icon"
        }))
  ], 2))
}