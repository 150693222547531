
import { defineComponent, ref, watch } from 'vue';
import Icon from '@/components/common/icon/Icon.vue';
import Checkbox from './inputs/Checkbox.vue';

export default defineComponent({
  name: 'SettingsRecord',
  props: {
    aiDisable: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const ai = ref(false);

    watch(ai, (value) => {
      emit('toggleAi', value);
    });
    return {
      ai,
    };
  },
  components: { Icon, Checkbox },
});
