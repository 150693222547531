interface ImageCropParams {
  width: number;
  height: number;
  currentWidth: number;
  currentHeight: number;
}

export function useVideoImage() {
  const getPositionImage = ({ width, height, currentHeight, currentWidth }: ImageCropParams) => {
    let xStart = 0;
    let yStart = 0;

    let aspectRadio: number, newWidth: number, newHeight: number;

    aspectRadio = currentHeight / currentWidth;

    if (currentHeight < currentWidth) {
      aspectRadio = currentWidth / currentHeight;
      (newHeight = height), (newWidth = aspectRadio * height);
      xStart = -(newWidth - width) / 2;
    } else {
      (newWidth = width), (newHeight = aspectRadio * width);
      yStart = -(newHeight - height) / 2;
    }

    return { xStart, yStart, newWidth, newHeight };
  };

  const createPoster = async (src: string): Promise<string> => {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

    return new Promise((res) => {
      const videoEl = document.createElement('video');
      videoEl.src = src;

      videoEl.addEventListener('loadeddata', async () => {
        canvas.width = 84;
        canvas.height = 84;
        videoEl.currentTime = 0;

        videoEl.addEventListener('seeked', async () => {
          const { xStart, yStart, newWidth, newHeight } = getPositionImage({
            width: 84,
            height: 84,
            currentHeight: videoEl.videoHeight,
            currentWidth: videoEl.videoWidth,
          });
          await ctx?.drawImage(videoEl, xStart, yStart, newWidth, newHeight);
          const base64 = canvas.toDataURL();
          videoEl.remove();
          res(base64);
        });
      });
    });
  };

  const createPreviewVideo = async (blob: string): Promise<string> => {
    try {
      const image = await createPoster(blob);
      return image.replace('data:image/png;base64,', '');
    } catch (error) {
      return '';
    }
  };

  return {
    createPoster,
    createPreviewVideo,
  };
}
