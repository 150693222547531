
import Icon from '@/components/common/icon/Icon.vue';
import { Ref, computed, defineComponent, onBeforeMount, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from '@/store';
import { api } from '@/utils/api';
import ButtonUser from '@/components/common/buttons/Button.vue';
// eslint-disable-next-line
// @ts-ignore
import { VideoEditor } from '@scope/nlp-web-sdk';
import debounce from 'lodash/debounce';
import { NLPEditingList } from '@/utils/editinglist';
import ProgressBar from '@/components/common/ProgressBar.vue';

import getBlobDuration from 'get-blob-duration';
import fixWebmDuration from 'fix-webm-duration';

export default defineComponent({
  name: 'VideoEditor',
  components: { Icon, ButtonUser, ProgressBar },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const closePage = () => {
      router.push('/producer-projects');
    };

    const currentStep = ref<string>('editing');
    const videoEditor = ref<any>(null);

    const getVideoParams = async () => {
      try {
        const projectId: string = route.params.projectId as string;
        const projectInfo = await api().get(`/api/project/${projectId}`);
        if (!projectId) return;
        api().setProjectAuth(`${projectId}:${projectInfo.auth}`, 1);
        store.dispatch('modals/getIsShowPreloader', { value: true });
        const rangeList = await api().get(`/api/rangelist/${projectId}`);
        const editingList = new NLPEditingList(projectId, rangeList, !rangeList.length, projectInfo.dt0);

        const videos: any = [];

        const cameras = projectInfo.cameras ? projectInfo.cameras : [1, 2, 3];

        const promises = cameras.map(async (item: number) => {
          const data = await api().get(`/api/project-track/${projectId}/${item}/lq`, {
            responseType: 'blob',
            timeout: 999999,
            headers: { accept: 'video/webm' },
          });
          const duration = await getBlobDuration(data);
          // const duration = await api().get(`/api/project-track-info/${projectId}/${item}`);

          const blob = data;
          if (blob.type === 'video/webm') {
            const fixData = await fixWebmDuration(blob, duration * 1000, { logger: false });
            videos[item - 1] = URL.createObjectURL(fixData);
          } else {
            videos[item - 1] = URL.createObjectURL(blob);
          }
        });

        await Promise.all(promises);
        store.dispatch('video/getList', editingList);
        store.dispatch('video/getVideos', videos);
        store.dispatch('modals/getIsShowPreloader', { value: false });
      } catch (error) {
        console.log(error);
        store.dispatch('modals/getIsShowPreloader', { value: false });
        store.dispatch('modals/getIsShowModalError', { value: true, text: 'Что-то пошло не так' });
      }
    };
    onBeforeMount(() => {
      if (route.params.projectId) {
        store.dispatch('modals/getIsShowPreloader', { value: true });
      }
    });

    onMounted(async () => {
      //eslint-disable-next-line
      // const videosArray = [video_new, video_new_copy];
      if (route.params.projectId) await getVideoParams();
      const videosArray = videos.value.slice();
      // editingList.value.generateCloudIds();
      if (!editingList.value) return;

      editingList.value?.convertToRanges();
      const rangeList: any = editingList.value?.exportAsRanges(true) || [];
      console.log(rangeList);

      videoEditor.value = new VideoEditor(rangeList, videosArray, 'timeLine', 'player');

      videoEditor.value.addEventListener(
        'addFragment',
        debounce((ev: any) => {
          console.log(ev);
          editingList.value?.addRange({ ...ev.detail, type: 3 }, true);
        }, 100)
      );

      videoEditor.value.addEventListener(
        'removeFragment',
        debounce((ev: any) => {
          editingList.value?.deleteRange(ev.detail.id);
        }, 100)
      );

      videoEditor.value.addEventListener(
        'updateFragment',
        debounce((ev: any) => {
          editingList.value?.updateRangeTime(ev.detail);
        }, 100)
      );
    });

    const setCurrentStep = (value: string) => {
      currentStep.value = value;
      if (value === 'download') {
        videoEditor.value.playerControlsHide();
        renderVideo();
      }
    };

    const store = useStore();

    const editingList: Ref<NLPEditingList | null> = computed(() => {
      return store.state.video.list || null;
    });

    const videos = computed(() => {
      return store.state.video.videos;
    });

    const isRenderVideo = ref(false);
    const percentRender = ref<number>(0);
    const isDownloadVideo = ref(false);
    const percentDownLoad = ref<number>(0);

    const videoDownLoad: any = ref(null);

    const renderVideo = async () => {
      await sendInCloudEditingList();
      isRenderVideo.value = true;
      const render_res = await api().renderVideo(
        store.state.projects.currentProjectId,
        (current: number, duration: number) => {
          percentRender.value = current;
        }
      );
      isRenderVideo.value = false;

      if (render_res === false) {
        store.dispatch('modals/getIsShowModalError', { value: true });
        return;
      }
    };

    const sendInCloudEditingList = async () => {
      if (!editingList.value) return;
      const rangeList = editingList.value.exportAsRanges(false);
      await api().uploadRangeList(rangeList);
      // router.push('/producer-projects');
    };

    const uploadVideo = async () => {
      if (isDownloadVideo.value) return;
      isDownloadVideo.value = true;
      videoDownLoad.value = await api().downloadVideo(store.state.projects.currentProjectId, (ev: any) => {
        percentDownLoad.value = +ev.progress.toFixed(1) * 100;
      });

      if (videoDownLoad.value === null) {
        store.dispatch('modals/getIsShowModalError', { value: true });
        return;
      }
    };

    const download = async () => {
      await uploadVideo();
      const url = URL.createObjectURL(videoDownLoad.value);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'video.mp4');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    };

    const camsUpload = computed(() => {
      const uploadsCams = [...store.state.upload.local, ...store.state.upload.remote];

      return uploadsCams.filter((item: any) => item !== undefined && item?.updated !== 0);
    });

    const shareVideo = async () => {
      await uploadVideo();
      if (typeof navigator.canShare === 'function') {
        const shareData = {
          files: [new File([videoDownLoad.value], 'video.mp4', { type: videoDownLoad.value.type })],
          //title: 'Project video',
          //text: 'Project video text',
        };
        if (navigator.canShare(shareData)) {
          await navigator.share(shareData);
        }
      }
    };

    const isUploadedAll = computed(() => {
      return store.getters['upload/isUploadedAll'];
    });

    //const isUploadedWasd = ref(false);

    return {
      closePage,
      setCurrentStep,
      videos,
      editingList,
      currentStep,
      renderVideo,
      percentRender,
      percentDownLoad,
      shareVideo,
      download,
      isDownloadVideo,
      isRenderVideo,
      camsUpload,
      isUploadedAll,
      //isUploadedWasd,
    };
  },
});
