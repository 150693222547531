
import { defineComponent, ref } from 'vue';
import Modal from '@/components/common/Modal.vue';
import Icon from '@/components/common/icon/Icon.vue';
import { api } from '@/utils/api';
import { useRouter } from 'vue-router';
import AppVersion from '@/components/common/AppVersion.vue';
import { NLPEditingList } from '@/utils/editinglist';
import { useStore } from '@/store';
import getBlobDuration from 'get-blob-duration';
import fixWebmDuration from 'fix-webm-duration';

export default defineComponent({
  name: 'ModalMenu',
  props: {
    isShowModal: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const router = useRouter();

    const menuItems = ref([
      {
        icon: 'main',
        text: 'Главная',
        disabled: false,
        click: () => {
          router.push('/roles');
        },
      },
      {
        icon: 'catalog',
        text: 'Мои проекты',
        disabled: false,
        click: () => {
          router.push('/producer-projects');
        },
      },
      {
        icon: 'videocam',
        text: 'Видеоредактор',
        disabled: false,
        click: async () => {
          const { id } = await api().post('/api/project-demo/1', {});
          console.log(id);
          openEditor(id);
          // router.push('/video-editor');
        },
      },
      {
        icon: 'logout',
        text: 'Выйти из аккаунта',
        disabled: false,
        click: () => {
          api().resetAppAuth();
          router.push('/');
        },
      },
      // { icon: 'catalog', text: 'FAQ', disabled: true },
      // { icon: 'message', text: 'Поддержка', disabled: true },
      // { icon: 'CheckInCircle', text: 'Пользовательское соглашение', disabled: true },
    ]);

    const store = useStore();

    const openEditor = async (idProject: string) => {
      try {
        router.push(`/video-editor/${idProject}`);
      } catch (error) {
        store.dispatch('modals/getIsShowPreloader', { value: false });
        store.dispatch('modals/getIsShowModalError', { value: true, text: 'Что-то пошло не так' });
      }
    };

    return {
      menuItems,
    };
  },
  components: { Modal, Icon, AppVersion },
});
