
import { defineComponent } from 'vue';
import Icon from '@/components/common/icon/Icon.vue';

export default defineComponent({
  name: 'RecButton',
  components: {
    Icon,
  },
  props: {
    buttonType: {
      default: 'started',
      type: String,
      validator: function (value: string) {
        return ['stopped', 'started', 'inactive'].includes(value);
      },
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const clickButton = () => {
      emit('click');
    };
    return {
      clickButton,
    };
  },
});
