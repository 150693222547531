
import { defineComponent, onMounted, ref, toRefs } from 'vue';
import Modal from '@/components/common/Modal.vue';
import ButtonUser from '@/components/common/buttons/Button.vue';
import { useStore } from '@/store';

export default defineComponent({
  components: { Modal, ButtonUser },
  name: 'ModalRenameProject',
  props: {
    project: {
      default: null,
      type: Object,
    },
    isShowModal: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const { project } = toRefs(props);
    const store = useStore();

    const name = ref('');
    const input = ref();
    onMounted(() => {
      name.value = project.value.name;
      input.value.focus();
    });

    const rename = () => {
      store.dispatch('projects/updateProject', { name: name.value, id: project.value.id });
      emit('close');
    };

    const close = () => {
      emit('close');
    };

    return {
      name,
      rename,
      close,
      input,
    };
  },
});
