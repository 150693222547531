
import { defineComponent, ref, Ref } from 'vue';
import Modal from '@/components/common/Modal.vue';
import RadioButton from '@/components/common/buttons/RadioButton.vue';
import ButtonUser from '@/components/common/buttons/Button.vue';

export default defineComponent({
  components: { Modal, RadioButton, ButtonUser },
  name: 'ModalSelectDevice',
  props: {
    isShowModal: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const videoDevices: Ref<Array<Partial<MediaDeviceInfo>>> = ref([]);
    const audioDevices: Ref<Array<Partial<MediaDeviceInfo>>> = ref([]);
    const videoInput: Ref<string> = ref('');
    const audioInput: Ref<string> = ref('');

    const getDevices = async () => {
      const data = await navigator.mediaDevices.enumerateDevices();
      videoDevices.value = data
        .filter((item) => item.kind === 'videoinput')
        .map((item) => {
          return {
            deviceId: item.deviceId,
            label: item.label,
          };
        });

      audioDevices.value = data
        .filter((item) => item.kind === 'audioinput')
        .map((item) => {
          return {
            deviceId: item.deviceId,
            label: item.label,
          };
        });
    };
    getDevices();

    const sendInputs = (ev: MouseEvent) => {
      if (ev?.shiftKey === true) {
        emit('selectInputs', {
          audioInput: audioInput.value || audioDevices.value[0].deviceId,
          videoInput: 'screen',
        });
        return;
      }
      emit('selectInputs', {
        audioInput: audioInput.value || audioDevices.value[0].deviceId,
        videoInput: videoInput.value || videoDevices.value[0].deviceId,
      });
    };

    return {
      videoDevices,
      audioDevices,
      videoInput,
      audioInput,
      sendInputs,
    };
  },
});
