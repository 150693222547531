import { Commit } from 'vuex';
import { NLPLoggedUser } from '@/utils/api';

export default {
  loggedUser({ commit }: { commit: Commit }, user: NLPLoggedUser) {
    commit('setLoggedUser', user);
  },
  operatorCamera({ commit }: { commit: Commit }, camera: number) {
    commit('setOperatorCamera', camera);
  },
};
