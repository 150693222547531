
import { ref, Ref, defineComponent, onBeforeMount } from 'vue';
import ButtonUser from './common/buttons/Button.vue';

export default defineComponent({
  name: 'PwaButton',
  components: { ButtonUser },
  setup() {
    const shown: Ref<boolean> = ref(false);
    const installEvent: any = ref(null);

    const dismissPrompt = () => {
      shown.value = false;
    };

    onBeforeMount(() => {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        installEvent.value = e;
        shown.value = true;
      });
    });

    const installPWA = () => {
      installEvent.value.prompt();
      installEvent.value.userChoice.then(() => {
        dismissPrompt();
      });
      shown.value = false;
    };

    return {
      dismissPrompt,
      installPWA,
      shown,
    };
  },
});
