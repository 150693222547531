import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-proto';
import { Resource } from '@opentelemetry/resources';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';

const serviceName = process?.env.OPENTELEMETRY_SERVICE_NAME;

class OpenTelemetry {
  public provider: WebTracerProvider;
  constructor() {
    this.provider = new WebTracerProvider({
      resource: new Resource({
        'service.name': serviceName,
      }),
    });
    this.init();
  }

  init() {
    // if (process?.env.NODE_ENV !== 'production') return;
    this.provider.addSpanProcessor(
      new SimpleSpanProcessor(
        new OTLPTraceExporter({
          // url: 'https://tracing.obs.mts.ru/otel-collector-endpoint-http/v1/traces',
          url: '/otel',
        })
      )
    );

    this.provider.register({
      contextManager: new ZoneContextManager(),
    });

    registerInstrumentations({
      instrumentations: [
        new DocumentLoadInstrumentation(),
        getWebAutoInstrumentations({
          '@opentelemetry/instrumentation-xml-http-request': {
            clearTimingResources: true,
          },
        }),
      ],
    });
  }
}

const telemetry = new OpenTelemetry();
// const initOpenTelemetry = () => {
//   const tracer = telemetry.provider.getTracer('custom-tracer');
//   const span = tracer.startSpan('init-open-telemetry');
//   console.log('cus trace');
//   setTimeout(() => {
//     span.end();
//   }, 10000);
// };

// initOpenTelemetry();

export default telemetry;
