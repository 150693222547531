import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "test" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonUser = _resolveComponent("ButtonUser")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.codecList, (str, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'str' + idx
      }, _toDisplayString(str), 1))
    }), 128)),
    _createVNode(_component_ButtonUser, {
      size: "l",
      text: "Share",
      onClick: _ctx.share
    }, null, 8, ["onClick"])
  ]))
}