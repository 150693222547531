import axios, { Axios } from 'axios';
import Cookie from 'js-cookie';

import store from '@/store/index';

let apiInstance: NLPApi | null = null;

interface NLPWebRTCPeerInfo {
  mid: string;
  ml: number;
  data: string;
}

interface NLPProjectInfo {
  id: string;
  name: string;
  image: string;
  auth: string;
  data: object;
}

const requestTimeout = 5000;
const requestMaxRedirects = 2;

interface NLPLoggedUserData {
  name: string;
  admin: boolean;
}

export type NLPLoggedUser = NLPLoggedUserData | null;

export function sleep(ms: number) {
  return new Promise((resolve) => {
    window.setTimeout(resolve, ms);
  });
}

class NLPApi {
  axios: Axios;
  private projectAuth: string | null = null;
  private appAuth: string | null = null;
  private loggedUser: NLPLoggedUser = null;
  private directorIsOnlineReported = 0;

  constructor(auth?: string) {
    const authHeaders: {
      Authorization?: string;
      'x-web-version': string;
    } = {
      'x-web-version': `${process.env.VUE_APP_GIT_HASH?.substring(0, 8)} ${process.env.VUE_APP_BUILD_DATE}`,
    };
    if (!auth) {
      const savedAuth = JSON.parse(window.localStorage.getItem('app.auth') || 'null');
      // prettier-ignore
      if (savedAuth !== null && ( (Date.now() - savedAuth.dt) < (86400000 * 10) ) && savedAuth.auth) {
        auth = savedAuth.auth;
      }
    } else {
      window.localStorage.setItem('app.auth', JSON.stringify({ dt: Date.now(), auth: auth }));
    }

    if (auth) {
      authHeaders.Authorization = `Bearer ${auth}`;
    }
    this.appAuth = auth || null;

    const projectAuth = JSON.parse(window.localStorage.getItem('app.projectauth') || 'null');
    // prettier-ignore
    if (projectAuth !== null && ( (Date.now() - projectAuth.dt) < (86400000 * 10) ) && projectAuth.auth) {
      this.projectAuth = projectAuth.auth;
      store.dispatch('projects/getProjectAuth', this.projectAuth)
      store.dispatch('auth/operatorCamera', projectAuth.camera);
    }

    this.axios = axios.create({
      timeout: requestTimeout,
      headers: authHeaders,
      responseType: 'json',
      responseEncoding: 'utf8',
      maxRedirects: requestMaxRedirects,
    });
  }

  async get(path: string, options?: object) {
    try {
      const data = await this.axios.get(path, options);
      return data.data;
    } catch (err) {
      console.log(`api GET ${path} error`, err);
      return null;
    }
  }

  async getCode(path: string, options?: object) {
    let data;
    try {
      data = await this.axios.get(path, options);
      return data.data;
    } catch (err) {
      console.log(`api GET ${path} error`, err);
      return data?.status;
    }
  }

  async delete(path: string, options?: object) {
    try {
      const data = await this.axios.delete(path, options);
      return data.data;
    } catch (err) {
      console.log(`api GET ${path} error`, err);
      return null;
    }
  }

  //eslint-disable-next-line
  async post(path: string, data: any, options?: object) {
    try {
      const rx_data = await this.axios.post(path, data, options);
      return rx_data.data;
    } catch (err) {
      console.log(`api POST ${path} error`, err);
      return null;
    }
  }

  isFullAuthorized() {
    return this.appAuth !== null;
  }

  isOperatorAuthorized() {
    return this.projectAuth !== null;
  }

  setProjectAuth(auth: string, camera: number) {
    this.projectAuth = auth;
    console.log('set project auth', auth);
    if (auth.includes(':')) {
      window.localStorage.setItem('app.projectauth', JSON.stringify({ dt: Date.now(), auth: auth, camera: camera }));
      store.dispatch('projects/getProjectAuth', auth);
    }
  }

  resetProjectAuth() {
    this.projectAuth = null;
    window.localStorage.removeItem('app.projectauth');
  }

  // авторизацию может вынесем в отдельный класс!?
  async setAppAuth(auth: string): Promise<NLPLoggedUser> {
    this.appAuth = auth;
    window.localStorage.setItem('app.auth', JSON.stringify({ dt: Date.now(), auth: auth }));
    this.axios = axios.create({
      timeout: requestTimeout,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
      responseType: 'json',
      responseEncoding: 'utf8',
      maxRedirects: requestMaxRedirects,
    });
    return await this.whoami();
  }

  resetAppAuth() {
    this.appAuth = null;
    this.loggedUser = null;
    window.localStorage.removeItem('app.auth');
  }

  async webrtcUpdateSdp(who: string, sdp: RTCSessionDescription | null) {
    if (this.projectAuth === null) throw new Error('Project is not authenticated');
    if (sdp === null) return;
    const rx_data = await this.post(
      `/api/pc/update/${who}/sdp`,
      { sdp: sdp.sdp },
      {
        headers: {
          'x-project-auth': this.projectAuth,
        },
      }
    );
    return rx_data;
  }

  async webrtcUpdatePeer(who: string, peer: RTCIceCandidate | null) {
    if (this.projectAuth === null) throw new Error('Project is not authenticated');
    const peerList = peer
      ? [
          {
            mid: peer.sdpMid,
            ml: peer.sdpMLineIndex,
            data: peer.candidate,
          },
        ]
      : [null];
    const rx_data = await this.post(
      `/api/pc/update/${who}/peers`,
      { peers: peerList },
      {
        headers: {
          'x-project-auth': this.projectAuth,
        },
      }
    );
    return rx_data;
  }

  async webrtcCleanupSignalling(who: string) {
    if (this.projectAuth === null) throw new Error('Project is not authenticated');
    const rx_data = await this.post(`/api/pc/cleanup/${who}`, '', {
      headers: {
        'x-project-auth': this.projectAuth,
      },
    });
    return rx_data;
  }

  async webrtcGetSdp(who: string): Promise<string> {
    if (this.projectAuth === null) throw new Error('Project is not authenticated');
    const rx_data = await this.get(`/api/pc/check/${who}/sdp`, {
      headers: {
        'x-project-auth': this.projectAuth,
      },
    });
    if (who[0] === 'o') this.directorIsOnlineReported = Date.now();
    return rx_data;
  }

  async webrtcGetPeers(who: string): Promise<Array<NLPWebRTCPeerInfo | null>> {
    if (this.projectAuth === null) throw new Error('Project is not authenticated');
    const rx_data = await this.get(`/api/pc/check/${who}/peers`, {
      headers: {
        'x-project-auth': this.projectAuth,
      },
    });
    if (who[0] === 'o') this.directorIsOnlineReported = Date.now();
    return rx_data;
  }

  async requestQrCode(projectId: string, camera: number): Promise<string> {
    try {
      const rx_data = await this.post(`/api/project/qr-request/${projectId}/${camera}`, {});
      return rx_data || '';
    } catch (err) {
      console.log('api error', err);
      return '';
    }
  }

  async checkQrCode(code: string): Promise<(NLPProjectInfo & { camera: number }) | null> {
    try {
      const rx_data = await this.post('/api/qr/auth', { code: code });
      if (rx_data === null) return null;
      return rx_data;
    } catch (err) {
      console.log('api error', err);
      return null;
    }
  }

  async whoami(): Promise<NLPLoggedUser> {
    if (this.appAuth === null) return Promise.resolve(null);

    const data = await this.get('/api/whoami');
    if (data === null) return null;
    this.loggedUser = {
      name: data.name,
      admin: !!data.admin,
    };
    console.log('Logged as', this.loggedUser);
    store.dispatch('auth/loggedUser', this.loggedUser);
    return this.loggedUser;
  }

  async uploadCameraPackets(camera: number, fd: FormData) {
    if (this.projectAuth === null) throw new Error('Project is not authenticated');
    const rx_data = await this.post(`/api/upload/${camera}`, fd, {
      headers: {
        'x-project-auth': this.projectAuth,
      },
      timeout: 100000,
    });
    return rx_data;
  }

  async uploadEditingList(list: Array<object>): Promise<Array<{ o: number; id: string }> | null> {
    if (this.projectAuth === null) throw new Error('Project is not authenticated');
    const rx_data = await this.post('/api/editinglist/all', list, {
      headers: {
        'x-project-auth': this.projectAuth,
      },
    });
    return rx_data;
  }

  //eslint-disable-next-line
  async uploadRangeList(list: Array<Array<object>>): Promise<any> {
    if (this.projectAuth === null) throw new Error('Project is not authenticated');
    const listCopy: Array<object> = [];
    // нужно что бы убрать поле opaque, а так же положить все дороги в один массив
    list.forEach((track) => {
      //eslint-disable-next-line
      track.forEach((el: any) => {
        listCopy.push({
          start: el.start,
          stop: el.stop,
          type: el.type,
          camera: el.camera,
          id: el.id,
          opts: {
            text: el.text,
            position: el.position,
            style: el.style,
          },
        });
      });
    });
    //eslint-disable-next-line
    listCopy.sort((A: any, B: any) => A.start - B.start);
    const rx_data = await this.post('/api/rangelist', listCopy, {
      headers: {
        'x-project-auth': this.projectAuth,
      },
    });
    return rx_data;
  }

  createProject(config: object): Promise<{ id: string; auth: string; shortlink: string }> {
    return this.post('/api/project/new', config);
  }

  async projectDirectorOnline(): Promise<number> {
    if (this.projectAuth === null) throw new Error('Project is not authenticated');
    const rx_data = await this.get('/api/pc/online', {
      headers: {
        'x-project-auth': this.projectAuth,
      },
    });
    if (rx_data === null) return -1;
    return rx_data * 1;
  }

  async reportDirectorIsOnline(): Promise<boolean> {
    if (Date.now() - this.directorIsOnlineReported < 5000) return Promise.resolve(true);
    if (this.projectAuth === null) throw new Error('Project is not authenticated');
    const rx_data = await this.post('/api/pc/online', '', {
      headers: {
        'x-project-auth': this.projectAuth,
      },
    });
    this.directorIsOnlineReported = Date.now();
    return !!rx_data;
  }

  async downloadVideo(projectId: string, progressCb?: (ev: Event) => void): Promise<Blob> {
    const rx_data = await this.get(`/download/${projectId}/video.mp4`, {
      responseType: 'blob',
      onDownloadProgress: progressCb,
      timeout: 0,
    });
    return rx_data;
  }

  async renderVideo(projectId: string, progressFunc?: (current: number, duration: number) => void): Promise<boolean> {
    const rx_data = await this.post(`/api/project-render/${projectId}`, '', { timeout: 10000 });
    if (!rx_data) return false;
    const startDt = Date.now();
    store.dispatch('upload/renderProgress', 0);
    for (;;) {
      await sleep(1000);
      if (Date.now() - startDt > 300000) return false;
      const render_data = await this.get(`/api/project-render/${projectId}`);
      if (render_data === null) return false;
      store.dispatch('upload/renderProgress', render_data.progress);
      if (progressFunc !== undefined) {
        progressFunc(render_data.progress, render_data.duration);
      }
      if (render_data.finished === true) return !render_data.error;
    }
  }

  async getDebugOptions() {
    try {
      const data = await this.get('/api/debug/options');
      return data || {};
    } catch (error) {
      console.log('error when get debug options', error);
      return {};
    }
  }

  async refreshDebugOptions() {
    window.debugOptions = await this.getDebugOptions();
    if (window.debugOptions.swVersion !== undefined) {
      const oldVersion = Cookie.get('swver');
      Cookie.set('swver', window.debugOptions.swVersion);
      if (oldVersion !== window.debugOptions.swVersion) {
        await this.sendDebug('swver-update', { version: window.debugOptions.swVersion });
        reloadPage();
      }
    } else {
      Cookie.remove('swver');
    }
  }

  /*
  async uploadWasd(projectId: string) {
    try {
      const data = await this.post(`/api/project-upload/wasd/${projectId}`, {});
      return data;
    } catch (error) {
      console.log('error uploadWasd', error);
    }
  }
  */

  sendCodecDebug(what: string, codecDebug: object) {
    return this.post(`/api/debug/codec/${what}`, codecDebug);
  }

  //eslint-disable-next-line
  sendDebug(tag: string, data: any) {
    if (typeof data !== 'string') data = JSON.stringify(data);
    return this.post(
      `/api/debug/log/${tag}`,
      { str: data },
      {
        headers: {
          'x-project-auth': this.projectAuth,
        },
      }
    );
  }
}

export async function createApi(auth?: string): Promise<NLPApi> {
  if (apiInstance !== null) return apiInstance;
  apiInstance = new NLPApi(auth);
  await apiInstance.whoami();
  return apiInstance;
}

export function api(): NLPApi {
  if (apiInstance === null) throw new Error('API instance not created');
  return apiInstance;
}

export function reloadPage() {
  const lastReloadDt: number = parseFloat(localStorage.getItem('lastReloadDt') || '0');
  if (Date.now() - lastReloadDt < 10000) {
    console.log('too frequent page reload');
    return;
  }
  caches.keys().then((cacheNames) => {
    cacheNames.forEach((cacheName) => {
      console.log('purge', cacheName);
      caches.delete(cacheName);
    });
  });
  fetch(`${window.location.href}?${Math.random().toString(36).substring(3)}`, {
    headers: {
      Pragma: 'no-cache',
      Expires: '-1',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    },
  }).then(() => {
    localStorage.setItem('lastReloadDt', '' + Date.now());
    window.location.reload();
  });
}

// для отладки
if (process?.env.NODE_ENV !== 'production') {
  //eslint-disable-next-line
  (window as any).api = api;
}
