import { EventEmitter } from 'eventemitter3';

export class NLPAppEvents extends EventEmitter {
  constructor() {
    super();
  }
}

const appEvents: NLPAppEvents = new NLPAppEvents();

// для отладки
if (process?.env.NODE_ENV !== 'production') {
  //eslint-disable-next-line
  (window as any).appEvents = appEvents;
}

export default appEvents;
