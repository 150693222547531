
import { defineComponent } from 'vue';
import Icon from './icon/Icon.vue';

export default defineComponent({
  name: 'PreloaderVue',
  setup() {
    return {};
  },
  components: { Icon },
});
