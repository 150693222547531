import { Commit } from 'vuex';

export default {
  async getIsShowModalError({ commit }: { commit: Commit }, { value, text }: { value: boolean; text: string }) {
    commit('setIsShowModalError', { value, text });
  },
  async getIsShowModalSuccess({ commit }: { commit: Commit }, value: boolean) {
    commit('setIsShowModalSuccess', value);
  },
  async getIsShowPreloader({ commit }: { commit: Commit }, { value, text }: { value: boolean; text: string }) {
    commit('setIsShowPreloader', { value, text });
  },
};
