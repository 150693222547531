import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", _mergeProps({
    class: [
      { disabled: _ctx.disabled },
      { 'button_type-icon': _ctx.icon !== '' },
      `button button_size-${_ctx.size} button_type-${_ctx.type}`,
    ],
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
  }, { class: _ctx.$attrs.class }), [
    _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          icon: _ctx.icon,
          class: "button__icon"
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true)
  ], 16))
}