import state, { State } from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import { Module } from 'vuex';
import { RootState } from '..';

const auth_module: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

export { State };

export default auth_module;

// type Store<S = State> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
//   commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
//     key: K,
//     payload: P,
//     options?: CommitOptions
//   ): ReturnType<Mutations[K]>;
// } & {
//   getters: {
//     [K in keyof Getters]: ReturnType<Getters[K]>;
//   };
// } & {
//   dispatch<K extends keyof Actions>(
//     key: K,
//     payload: Parameters<Actions[K]>[1],
//     options?: DispatchOptions
//   ): ReturnType<Actions[K]>;
// };
