import state, { State } from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import { RootState } from '..';
import { Module } from 'vuex';

const projects_module: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

export { State };

export default projects_module;
