
import { computed, defineComponent, toRefs } from 'vue';
export default defineComponent({
  model: {
    prop: 'modelValue',
    // event: 'change',
  },
  props: {
    label: { type: String, default: '', required: true },
    value: { type: String, default: undefined },
    modelValue: { type: String, default: undefined },
    idGroup: { type: String, default: '' },
  },
  setup(props, { emit }) {
    const { value, modelValue } = toRefs(props);

    const isChecked = computed(() => {
      return value.value === modelValue.value;
    });

    const change = (event: Event) => {
      emit('update:modelValue', (event.target as HTMLInputElement).value);
    };
    return {
      isChecked,
      change,
    };
  },
});
