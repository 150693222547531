import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonUser = _resolveComponent("ButtonUser")!

  return (_ctx.shown)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ButtonUser, {
          size: "l",
          type: "secondary",
          onClick: _ctx.installPWA,
          text: "Скачать приложение"
        }, null, 8, ["onClick"])
      ]))
    : _createCommentVNode("", true)
}