import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4562591c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  ref: "settingsButtons",
  class: "preview-video__settings"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleIconButton = _resolveComponent("CircleIconButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['preview-video', { active: _ctx.active }, { disabled: _ctx.disabled && !_ctx.active }])
  }, [
    _withDirectives(_createVNode(_component_CircleIconButton, {
      class: "preview-video__button-add",
      icon: "plus",
      type: "primary",
      onClick: _ctx.addConnection
    }, null, 8, ["onClick"]), [
      [_vShow, !_ctx.isConnected]
    ]),
    _withDirectives(_createElementVNode("video", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selectPgm'))),
      ref: "input_video",
      class: _normalizeClass(["preview-video__video", { 'preview-video__flip': _ctx.flipVideo }]),
      style: {},
      controls: false,
      autoplay: "",
      muted: ""
    }, null, 2), [
      [_vShow, _ctx.isConnected]
    ]),
    _createVNode(_Transition, {
      name: "slide-fade",
      oouts: ""
    }, {
      default: _withCtx(() => [
        (_ctx.isShowSettings && _ctx.isConnected)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_ctx.isShowChangeCam)
                ? (_openBlock(), _createBlock(_component_CircleIconButton, {
                    key: 0,
                    icon: "reset",
                    class: "preview-video__settings-btn-reset"
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_CircleIconButton, {
                icon: "delete",
                onClick: _ctx.stopConnection
              }, null, 8, ["onClick"])
            ], 512))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}