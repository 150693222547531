import { createApp } from 'vue';
import Cookie from 'js-cookie';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store, { key } from './store';

import * as Sentry from '@sentry/vue';

import '@/assets/styles/base.scss';
import '@/assets/styles/snyato.scss';
import '@fontsource/roboto';

import { createApi, api, reloadPage } from './utils/api';

import appEvents from '@/utils/events';
import StoreUtils from '@/utils/store';

// init openTelemetry
import './document-load';

if (process.env.VUE_APP_AUTH) {
  createApi(process.env.VUE_APP_AUTH);
} else {
  await createApi();
}

if (process.env.VUE_APP_PROJECTAUTH) {
  api().setProjectAuth(process.env.VUE_APP_PROJECTAUTH, Number(process.env?.VUE_APP_PROJECTAUTH_CAMERA) || 0);
}

//prettier-ignore
console.log(
  'git version',
  process.env.VUE_APP_GIT_HASH,
  process.env.VUE_APP_BUILD_DATE
);

console.log('cookie swver', Cookie.get('swver'));

appEvents.on('app:checkVersion', () => {
  if (window.matchMedia('(display-mode: standalone)').matches) return;
  fetch(`/version.txt?${Math.random().toString(36).substring(3)}`, {
    headers: {
      Pragma: 'no-cache',
      Expires: '-1',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    },
  })
    .then((res) => res.text())
    .then((version) => {
      console.log('remote version', version);
      if (process.env.VUE_APP_GIT_HASH !== version) {
        reloadPage();
      }
    });
});

appEvents.emit('app:start');

const app: any = createApp(App);
app.use(store, key);
app.use(router);

if (process?.env.NODE_ENV === 'production') {
  const sentryCreds = process?.env.VUE_APP_SENTRY?.split(':') || [];
  Sentry.init({
    app,
    dsn: `https://${sentryCreds[0]}@${window.location.host}/sentry/${sentryCreds[1]}`,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', /^\/api/],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

app.mount('#app');

StoreUtils.store = store;

// нужно для включения отладки "на ходу"
declare global {
  interface Window {
    // eslint-disable-next-line
    debugOptions: any;
  }
}

window.debugOptions = {};
await api().refreshDebugOptions();
console.log('Debug options', window.debugOptions);
