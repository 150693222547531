
import { defineComponent, ref } from 'vue';
// import Icon from './icon/Icon.vue';
import ModalMenu from '../modals/ModalMenu.vue';

export default defineComponent({
  name: 'BurgerMenu',
  components: { ModalMenu },
  setup(props, { emit }) {
    const isShowMenu = ref(false);

    const onClickBurger = () => {
      isShowMenu.value = !isShowMenu.value;
    };
    return {
      onClickBurger,
      isShowMenu,
    };
  },
});
