
import { defineComponent, onMounted } from 'vue';

//import { NLPMediaRecorder } from 'src/utils/mediarecorder';

import { NLPVideoUpload, nlpTimeRange, NLPTimeRange, NLPVideoCaptureDatabaseRecord } from '@/utils/videocapture';

function testAgainstRange(
  list: Array<NLPVideoCaptureDatabaseRecord>,
  ranges: Array<NLPTimeRange>
): Array<{ count: number; range: NLPTimeRange }> {
  return ranges.map((R) => {
    return {
      count: list.reduce((cntr, packet) => {
        if (packet.dt >= R.start && packet.dt <= R.stop) ++cntr;
        return cntr;
      }, 0),
      range: R,
    };
  });
}

export default defineComponent({
  name: 'TestFrames',
  components: {},
  setup() {
    onMounted(async () => {
      console.log('test frames');
      const U = new NLPVideoUpload('a6b2d00e-992e-4de3-a381-88e2c8f6b1e4');
      await U.open();

      /*
      const ranges: Array<NLPTimeRange> = [
        nlpTimeRange(1684842441881, 1684842442048),
        nlpTimeRange(1684842442081, 1684842442265),
        nlpTimeRange(1684842443015, 1684842443148),
        nlpTimeRange(1684842452910, 1684842455254),
      ];
      */
      //const ranges: Array<NLPTimeRange> = [nlpTimeRange(1684842441881, 1684842470629)];

      /*
      const list: Array<NLPVideoCaptureDatabaseRecord> = [];

      for await (const val of U.db.walkPacketsRanges(ranges)) {
        list.push(val);
      }

      console.log(list);

      console.log(testAgainstRange(list, ranges));
      */

      //await U.uploadRange(1, ranges, 100);
      console.log(await U.uploadAll(2, 100));
      //console.log(await U.db.getProjectHeader());
      //console.log(await U.db.getProjectHeader());
    });

    return {};
  },
});
