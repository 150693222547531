/**
 * зачем этот файл ? для передачи событий используется сериализация
 * в строки - массивы, где названия аргиментов не указанны. нужно для
 * минимизации передаваемых данных. что бы не ошибаться в порядке
 * следования аргументов в массиве в этом файле хрантся их описание
 * и функции для серализации/десереализации
 */
import { NLPP2PMessageType, NLPP2PMessage, NLPParsedMessageData, NLPregisterEventParser } from './p2p-protocol';

/**
 * класс p2p события основанный на p2p сообщении
 */
export class NLPP2PEvent extends NLPP2PMessage {
  constructor(eventName: string, ...opts: NLPParsedMessageData) {
    super(NLPP2PMessageType.Event, [eventName, ...opts]);
  }

  get eventName(): string {
    return this.data[0] as string;
  }

  get eventArgs(): NLPParsedMessageData {
    return this.data.slice(1);
  }

  /**
   * Мутация p2p сообщения в p2p событие методом заноса необходымых свойств.
   * @param ev сообщение p2p
   * @returns мутировавший объект p2p события
   */
  static mutateToEvent(ev: NLPP2PMessage): NLPP2PEvent {
    if (ev instanceof NLPP2PEvent) return ev;
    if ('eventName' in ev) return ev as NLPP2PEvent;

    //prettier-ignore
    Object.defineProperty(
      ev,
      'eventName',
      Object.getOwnPropertyDescriptor(NLPP2PEvent.prototype, 'eventName') as PropertyDescriptor
    );
    Object.defineProperty(
      ev,
      'eventArgs',
      Object.getOwnPropertyDescriptor(NLPP2PEvent.prototype, 'eventArgs') as PropertyDescriptor
    );
    return ev as NLPP2PEvent;
  }
}

type EventMessageParser = (ev: NLPP2PEvent) => object;

/**
 * синглтон для хранения "код как документация" про собтытия в p2p
 */
class P2PEventsRegistry {
  // тут описываются события в p2p протоколе в виде
  // генераторов объектов p2p событий

  /**
   * событие "начать съемку", отправляется режиссером
   * @param camera номер камеры
   * @returns объект события
   */
  start(camera: number, masterDt: number): NLPP2PEvent {
    return new NLPP2PEvent('start', camera, masterDt);
  }

  /**
   * событие "переключить камеру", отправляется режиссером
   * @param camera номер новой камеры
   * @returns объект события
   */
  take(camera: number): NLPP2PEvent {
    return new NLPP2PEvent('take', camera);
  }

  /**
   * событие "остановить съемку", отправляется режиссером
   * @returns объект события
   */
  stop(): NLPP2PEvent {
    return new NLPP2PEvent('stop');
  }

  /**
   * событие "удалить соединение", отправляется режиссером
   * @returns объект события
   */
  delete(): NLPP2PEvent {
    return new NLPP2PEvent('delete');
  }

  /**
   * событие "повторная запись", отправляется режиссером
   * @returns объект события
   */
  restart(camera: number): NLPP2PEvent {
    return new NLPP2PEvent('restart', camera);
  }

  /**
   * событие "заливай видосик", отправляется режиссером
   * @returns объект события
   */
  uploadRanges(camera: number, ranges: Array<Array<number>>, totalLength: number): NLPP2PEvent {
    return new NLPP2PEvent('uploadRanges', camera, ranges, totalLength);
  }

  /**
   * событие "заливаю видосик", отправляется оператором
   * @returns объект события
   */
  uploadProgress(camera: number, countFrames: number, totalFrames: number, done: boolean, error: boolean): NLPP2PEvent {
    return new NLPP2PEvent('uploadProgress', camera, countFrames, totalFrames, done, error);
  }

  private parsers: Map<string, EventMessageParser> = new Map();

  // тут делается магия по созданию десериализаторов p2p событий
  // основываясь на методах данного класса
  constructor() {
    const descs = Object.getOwnPropertyDescriptors(P2PEventsRegistry.prototype);
    for (const name in descs) {
      if (name === 'constructor' || name[0] === '_') continue;
      const desc = descs[name];
      const extractArgs = /^[^(]+\(([^)]*)\)/;
      const re = extractArgs.exec(desc.value.toString());
      if (re === null) throw new Error(`Can't get event ${name} argument list`);
      const argNames = re[1].split(',');
      const parser: Array<string> = [
        `if (ev.data[0]!=='${name}') throw new Error('Can not parse event '+ev.data[0]+' in parser of "${name}"');`,
        `const [${argNames.join(',')}]=ev.data.slice(1);`,
        `return {${argNames.join(',')}};`,
      ];
      //prettier-ignore
      const fn: EventMessageParser = (new Function('ev', parser.join('\n'))) as EventMessageParser;
      this.parsers.set(name, fn);
    }
    //console.log(this.parsers);
  }

  _parse(ev: NLPP2PEvent) {
    //console.log(ev, 'ev parse', ev.eventName);
    const parser = this.parsers.get(ev.eventName);
    if (parser === undefined) throw new Error(`Can't parse unknown event ${ev.eventName}`);
    return parser(ev);
  }
}

const NLPP2PEvents = new P2PEventsRegistry();

function NLPParseP2PEvent(msg: NLPP2PMessage) {
  const ev: NLPP2PEvent = NLPP2PEvent.mutateToEvent(msg);
  //console.log(ev.eventName);
  return NLPP2PEvents._parse(ev);
}

NLPregisterEventParser(NLPParseP2PEvent);

export { NLPP2PEvents, NLPParseP2PEvent };
